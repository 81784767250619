  import { AfterViewInit, Component, DoCheck, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BoxService } from 'src/app/bloom/services/box-service.service';
// import { ActivatedRoute, Router } from '@angular/router';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { MatExpansionPanel } from '@angular/material/expansion';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ListPanelService } from '../list-panel.service';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChartService } from 'src/app/shared/services/chart.service';
import { AutomationService } from 'src/app/bloom/services/automation.service';

interface BOX_CONFIG {
  boxId: string,
  boxObjectId: string,
  connectionId: string,
  boxName: string,
  attributeOptions: any[],
  getFnOptions: any[],
  getFn?: any
}

enum FilterType {
  static_filter = "Static Filter",
  page_filter = "Page Filter",
  navigation_filter = "Navigation filter"
}

interface LIST_PANEL_FILTERS {
  filterEnabled?: boolean,
  filterItems?: FILTER_ITEM[]
}

interface FILTER_ITEM {
  attribute: any,
  operator: string,
  value: any,
  dataType: string,
  filterType: string,
  templateSignature?: string
}

@Component({
  selector: 'app-list-panel-dialog',
  templateUrl: './list-panel-dialog.component.html',
  styleUrls: ['./list-panel-dialog.component.css']
})
export class ListPanelDialogComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {

  DEFAULT_IMAGE_SIZE: number = 5
  DEFAULT_LIST_SIZE: number = 10

  pageMeta: any;
  panelMeta: any;
  filteredAttributes: any;
  attributesForFilter: any;
  filteredStatusAttributes: any
  filteredSortAttributes: any
  // filteredPrimaryAttributes: any


  pageMetaSubscription: any

  selectAttributeControl = new UntypedFormControl();
  // selectFilterAttributeControl = new UntypedFormControl();
  selectFilterAttributeForm = new UntypedFormGroup({});
  selectStatusFieldControl = new UntypedFormControl();
  selectSortAttributeForm = new UntypedFormGroup({});
  // sortAttributeControl = new UntypedFormControl();

  // primaryAttributeControl = new UntypedFormControl()
  selectedBoxName: any;
  selectedConnectionId: any;
  selectedBoxId: any;
  isBoxObjectSelected: boolean = false;
  boxConfigToken: any;
  selectedBoxObjectId: any;
  boxObjectAttributes: any[];
  isAtrributeReady: boolean = false;
  isReadyToSave: boolean = false;
  dataSourceSelected: boolean = false;
  noDataMessage: any;

  listAttributes: any = []
  unselectedListAttributes: any = []

  boxObjects: any;
  isReceivedBoxObjects: boolean = false;
  canGetBoxObjects: boolean = false;
  isOptionsToCollect: boolean = false;
  attributeOptions: any[] = []
  getFnOptions: any[] = []
  getFn: any
  isGetOptionsToCollect: boolean = false;
  attributeOptionsError: boolean = false;
  faultyAttrOptions: any[] = []
  getOptionsError: boolean = false;
  faultyGetOptions: any[] = []
  groupingFieldError: boolean = false

  connectionList: any[] = []
  connectionListRaw: any[] = []
  connectionListLength: number = 0
  boxFunctions: any[] = []

  isBoxConfigError: boolean = false;
  isBoxObjectConfigError: boolean = false;
  isAttributeError: boolean = false;
  boxConfigError: any = ''
  boxObjectConfigError: any = {};
  attributeError: any = '';

  firstHit: boolean = false
  configChanged: boolean = false;   // this flag tells whether the existing configuration is changed (applies when !firstHit)
  attributeSpinner: boolean = false
  boxFunctionSpinner: boolean = false
  gettingObjFunSpinner: boolean = false

  terminationError: boolean = false;
  terminationErrorMessage: string;
  lockLoadInitialData: boolean = false;
  boxAttributes: any;

  hoveredIndex: number = -1;
  navigationPanelOpened: boolean;
  panelOpenedFor: string;

  selectedFieldType: string = ''
  listLabelFieldValue: string = ''
  listIconFieldValue: string = ''
  listLabelColumnName: string = ''
  listIconColumnName: string = ''

  saveDisabled: boolean = false
  disableBoxObjectInput: boolean = false
  refreshObjects: boolean = false;

  listPanelTitle: string;
  valueInjectedInDom: boolean = false
  primaryAttribute: any
  isPrimaryExists: boolean = false
  primaryAttributeName: string

  isAppSelected: boolean = false
  widgetSetOld: any[] = []
  panelMetaOld: any = {}

  filter: LIST_PANEL_FILTERS = {
    filterEnabled: false,
    filterItems: []
  }
  availableInputParams: any = {
    options: {}
  }
  collectAttrOptions: boolean = false
  collectGetOptions: boolean = false
  objectFuntions: any[] = []
  getAttrFn: any
  boxChanged: boolean = false
  boxObjectChanged: boolean = false

  updateFn: any
  updateFnOptions: any
  selectedConnection: any

  testt: any = "testt <br/> testt <br/>"

  boxConfigSetup: BOX_CONFIG

  viewTypes: any = {
    views: ['table', 'card', 'board'],
    defaultView: 'table',
    boardStatusColumn: '',
    userCanChoose: true,
    table: {name: 'table', displayName: 'Table', userCanChoose: true, icon: "list"},
    card: {name: 'card', displayName: 'Card', userCanChoose: true, icon: "grid_on"},
    board: {name: 'board', displayName: 'Board', userCanChoose: false, icon: "view_kanban"},
  }

  viewTypesDisp: any = {
    table: {name: 'table', displayName: 'Table', userCanChoose: true, icon: "list"},
    card: {name: 'card', displayName: 'Card', userCanChoose: true, icon: "grid_on"},
    board: {name: 'board', displayName: 'Board', userCanChoose: false, icon: "view_kanban"},
  }

  availableOperators: any[] = [
    { name: '> Greater Than', value: '>'},
    { name: '> Smaller Than', value: '<'},
    { name: '= Equal', value: '='},
    { name: '!= Not Equal', value: '!='},
  ]

  newFilter: FILTER_ITEM = {
    attribute: '',
    operator: '=',
    value: '',
    dataType: '',
    filterType: 'static_filter'
  }
  filterTypes: any[] = [
    {name: "Static Filter", icon:"text_fields", value: 'static_filter', description: 'User specified hardcoded filter'},
    {name: "Page Filter", icon:"find_in_page", value: 'page_filter', description: 'Some value from the page is used as filter value i.e. filter value depends on another element in this page'},
    {name: "Navigation Filter", icon:"link", value: 'navigation_filter', description: 'An attribute vlaue coming from the navigation is used as filter value'},
  ]

  filterTypeLogoMap: any = {
    "static_filter": {
      logo: "text_fields",
      name: "Static Filter"
    } ,
    'page_filter': {
      logo: "find_in_page",
      name: "Page Filter"
    } ,
    "navigation_filter": {
      logo: "link",
      name: "Navigation Filter"
    }
  }

  widgetMap = {
    list: ['label', 'image', 'link', 'datetime', 'button', 'icon', 'tags'],
    'label': {
      name: "Text",
      icon: "text_fields"
    },
    'image': {
      name: "Image",
      icon: "image"
    },
    'link': {
      name: "Link",
      icon: "link"
    },
    'datetime': {
      name: "Date + Time",
      icon: "event"
    },
    'button': {
      name: "Button",
      icon: "crop_7_5"
    },
    'icon': {
      name: "Icon",
      icon: "emoji_symbols"
    },
    'tags': {
      name: "Tags",
      icon: "sell"
    },
  }

  customWidgetMap = {
    list: ['attribute', 'label', 'icon', 'serial', 'button'],
    'attribute': {
      name: "Attribute",
      icon: "edit_attributes"
    },
    'label': {
      name: "Text",
      icon: "text_fields"
    },
    'icon': {
      name: "Icon",
      icon: "emoji_symbols"
    },
    'serial': {
      name: "Serial",
      icon: "tag"
    },
    'button': {
      name: "Button",
      icon: "crop_7_5"
    }
  }

  customAttribute: any = {}

  availableActions: any = [
    {code: "navigation", name: "Navigation" },
    {code: 'action', name: "Action"},
    {code: 'popup-edit', name: "Edit Row Data", disabled: true},
    {code: 'popup-view', name: "View Row Data"},
    {code: 'popup-view-edit', name: "View And Edit Row Data", disabled: true}
  ]

  availablePaginations: any = [
    {code: "pagebypage", name: "Page By Page" },
    {code: 'singlepage', name: "Single Page"}
  ]

  CHART_ICON: any = {
    'bar': "bar_chart",
    'pie': "pie_chart",
    'line': "show_chart",
    'gauge': "data_exploration",
    'scorecard': "score"
  }

  dataModel: any
  dynamicFilterSpinner: boolean = false
  showDynamicFilters: boolean = false
  pageFilters: any[] = []

  summaryViewIntro: string = "Summary view lets you show summary charts based on your list data on top of the list."
  isBoxSupportGroup: boolean = false

  sort: any = {
    sortEnabled: false,
    sortAttributes: [] // array of {attribute: [object], order: 'ASC' | 'DESC'}
  }

  cardNavigationPanelOpened: boolean = false

  chartConfigOpenedIndex: number = -1
  // @ViewChild('wholeCardSettingsExpansion') navSettingsExpansion: MatExpansionPanel;

  @ViewChild('closeButton') closeButton;
  @ViewChild('groupingAttributeInput') groupingAttributeInput;
  attributeActions: any;
  isBrowser: any;

  attributeDisplayName: string;

  constructor(
    public dialogRef: MatDialogRef<ListPanelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private MetaService: MetaService,
    private boxService: BoxService,
    private pageService: PageService,
    private WidgetUtilityService: WidgetUtilityService,
    private listPanelService: ListPanelService,
    private _snackBar: MatSnackBar,
    private chartService: ChartService,
    private automationService: AutomationService,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    this.panelMeta = data.panelMeta
    this.pageMeta = data.pageMeta

    console.log("page meta initialized", this.pageMeta)
  }


  ngOnInit(): void {
    if(!this.isBrowser) return;
    // this.panelMeta = this.data.panelMeta
    console.log("pageservice", this.pageService)
    if(!this.panelMeta.dataSource) this.panelMeta.dataSource = "app";
    if (this.data.firstHit) {
      this.firstHit = true
      console.log("first hit! building new panel...");
      this.panelMeta.loadInitialData = true
      this.panelMeta.paginationEnabled = true
      if(!this.panelMeta.paginationType){
        this.panelMeta.paginationType = "pagebypage";
      }
      this.panelMeta['defaultListSize'] = this.DEFAULT_LIST_SIZE
      this.panelMeta.noDataMessage = this.panelMeta.noDataMessage || "There is no data to be shown here";
    } else {
      this.firstHit = false;

      this.panelMetaOld = JSON.parse(JSON.stringify(this.panelMeta))
      // this.widgetSetOld = JSON.parse(JSON.stringify(this.panelMeta.listWidgetSet || []))

      console.log("panel meta received in list panel onInit:", this.panelMeta)
      this.listAttributes = this.panelMeta.listAttributes

      this.selectedBoxId = this.panelMeta.boxId
      this.selectedBoxName = this.panelMeta.boxName || '';
      this.selectedConnectionId = this.panelMeta.connectionId || '';
      this.selectedBoxObjectId = this.panelMeta.boxObjectId
      this.boxConfigToken = this.panelMeta.boxConfigToken
      this.viewTypes = this.panelMeta.viewTypes ? JSON.parse(JSON.stringify(this.panelMeta.viewTypes)) : this.viewTypes
      this.filter = this.panelMeta?.filter || this.filter;
      console.log("filter", this.filter)


      if(this.filter.filterItems && this.filter.filterItems.length > 0) {
        for(var i = 0; i < this.filter.filterItems.length; i ++){
          this.selectFilterAttributeForm.addControl("form" + i, new UntypedFormControl());
          let valueMap = {
            __id: this.filter.filterItems[i].attribute,
            dataType: this.filter.filterItems[i].dataType || "string"
          }
          this.selectFilterAttributeForm.patchValue({[`form${i}`]: valueMap});
        }
      }

      if(this.panelMeta?.sort && this.panelMeta?.sort.sortAttributes?.length){
        this.sort = this.panelMeta.sort
      }

      // this.findPrimaryAttribute()

      // initialize list panel title
      this.listPanelTitle = this.panelMeta.listPanelTitle || `${this.selectedBoxName || "Starch"} - ${this.selectedBoxObjectId} - Listing`
      this.panelMeta.noDataMessage = this.panelMeta.noDataMessage || "There is no data to be shown here";

      this.attributeOptions = this.panelMeta.attributeOptions
      this.getFnOptions = this.panelMeta.getFnOptions
    }

    //SUBSCRIBE TO PAGE META
    this.pageMetaSubscription = this.MetaService.pageMeta.subscribe(meta => {
      // console.log("list panel dialog onInit: page meta:", meta)
      this.pageMeta = meta;
    })

    // // SUBSCRIBE TO DATA MODEL
    // this.pageService.$dataModelSub.subscribe(dataModel => {
    //   this.dataModel = dataModel
    //   console.log("dataModel received in list panel dialog", this.dataModel)

    //   // look for filter binding data in data model
    // })

    //BY DEFAULT KEEP ENABLED LOAD INITIAL DATA AND PAGINATION ENABLED


    // FILTER FOR BOX ATTRIBUTES
    this.filteredAttributes = this.selectAttributeControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._attributeFilter(value))
    );

    // FILTER FOR BOX ATTRIBUTES FOR STATUS
    this.filteredStatusAttributes = this.selectStatusFieldControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._statusAttributeFilter(value))
    );

    // // FILTER FOR BOX ATTRIBUTES FOR PRIMARY ATTRIBUTE
    // this.filteredPrimaryAttributes = this.primaryAttributeControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => (typeof value === 'string' ? value : value.__id)),
    //   map(value => this._primaryAttributeFilter(value))
    // );

    //reset the data variable, so that if dialog closes unexpectedly, it returns the reset value of data
    this.data = false
  }

  ngAfterViewInit(): void {

  }

  setFilterList(i){
    // FILTER FOR LIST PANEL FILTERS
    this.attributesForFilter = this.selectFilterAttributeForm.get(`form${i}`).valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value['__id'])),
      map(value => this._filterAttributesFilter(value))
    );
  }

  sortChanged(event){
    console.log("[LIST PANEL DIALOG] sort changed", event)
    this.sort = event;
  }

  ngDoCheck(): void {
    // when grouping attribute input is ready, set previous value in case of 2nd time config opening
    if(!this.valueInjectedInDom && this.groupingAttributeInput){
      if(this.viewTypes.defaultView == 'board' || this.viewTypes['board'].userCanChoose){
        this.groupingAttributeInput.nativeElement.value = this.viewTypes.boardStatusColumn.name || this.viewTypes.boardStatusColumn.__id || ''
      }
      this.valueInjectedInDom = true
    }
  }

  ngOnDestroy(): void {
    this.pageMetaSubscription.unsubscribe()
  }

  selectAttributeMenuOpened(){
    console.log("this.unselectedListAttributes", this.boxObjectAttributes)
    console.log("this.listAttributes", this.listAttributes)
    this.boxObjectAttributes.forEach((e) => {
      e.isColumnSelected = false;
      this.listAttributes.forEach((list) => {
        if(e.__id == list.__id ){
          e.isColumnSelected = true;
        }
      })
    })
  }

  newListAttribute(event: any) {
    console.log("new list attribute:", event.option.value)
    let attr = event.option.value
    attr['sortEnabled'] = false
    attr['show_hide'] = false
    attr['fieldType'] = 'attribute'
    attr['widgetType'] = 'label'
    this.listAttributes.push(attr)
    this.excludeSelected()
    this.selectAttributeControl.patchValue('')

    if(this.listAttributes.length){
      this.isReadyToSave = true
    }
  }


  //-----------------------------------------FILTERS------------------------------------

  private _attributeFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.unselectedListAttributes.filter(option => option.__id.toLowerCase().includes(filterValue));
  }

  //filter for attributes for status field
  private _statusAttributeFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.boxObjectAttributes.filter(option => option.__id.toLowerCase().includes(filterValue));
  }

  //filter for attributes for adding filter
  private _filterAttributesFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let filterables: any[] = this.boxObjectAttributes.filter((attr: any) => attr) //.filterable
    return filterables.filter(option => option.__id.toLowerCase().includes(filterValue));
  }

  displayFn(attr) {
    return attr && attr.__id ? attr.__id : ''
  }

  //-----------------------------------------FUNCTIONS--------------------------------------

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listAttributes, event.previousIndex, event.currentIndex);
  }

  reorderNestedFieldsForAttr(event: CdkDragDrop<any[]>, attrIndex: number) {
    moveItemInArray(this.listAttributes[attrIndex]['nestedProperties'], event.previousIndex, event.currentIndex);
  }

  dropSummaryChart(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.panelMeta.summary.charts, event.previousIndex, event.currentIndex);
    console.log("charts rearranged", this.panelMeta.summary.charts)
  }

  appSelected(e){
    console.log("appselected", e);
    if (e.supports?.includes('group')) this.isBoxSupportGroup = true
    this.selectedBoxId = e.__id;
    if(this.selectedBoxId){
      this.isAppSelected = true

      this.boxService.setBoxObjectDisplayNames(this.selectedBoxId)
      this.attributeDisplayName = this.boxService.attributeDisplayName;
    }
  }

  getSelectedConnectionId(box){
    if(box != 'starch') return this.selectedConnectionId;
    return
  }

  getSelectedBoxId(){
    let box = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
    return box;
  }


  baseSelected(base){
    if('starch' !== this.selectedBoxId){
      this.configChanged = true
      this.isGetOptionsToCollect = false
      this.isOptionsToCollect = false
      this.saveDisabled = true

      this.refreshObjects = true
      this.disableBoxObjectInput = true
    }

    this.selectedBoxId = 'starch';
    this.panelMeta.baseMap = {
      box_id: base.storage_box,
      base_id: base._id
    }
    this.panelMeta.baseId = base._id;
    this.boxConfigToken = base.storage_token;
    this.getBoxFunctions(this.panelMeta.baseMap.box_id, this.boxConfigToken);
  }
  /** --------------------------------------boxSelected---------------------------
   * fires when a box is selected in box-select component
   * @param box the selected box will come from boxSelect component's output boxInput
   */
  boxSelected(box: any) {
    console.log("selected box", box)
    console.log("selected boxId", this.selectedBoxId)
    this.selectedConnection = box
    // check if config changed and keep flag
    if(box.box_id !== this.selectedBoxId){
      this.boxChanged = true
      this.isGetOptionsToCollect = false
      this.isOptionsToCollect = false
      this.saveDisabled = true

      this.refreshObjects = true
      this.disableBoxObjectInput = true
    }

    this.selectedBoxName = box.name
    this.selectedBoxId = box.box_id;
    this.boxConfigToken = box.box_token
    this.selectedConnectionId = box._id

    // this.configChanged = true

    this.getBoxFunctions(this.selectedBoxId, this.boxConfigToken)
  }

  async getBoxFunctions(box, token){
    this.canGetBoxObjects = false
    this.boxFunctionSpinner = true
    let res = await this.boxService.getBoxFunctions(box, token)
    console.log("box functions received", res)
    this.boxFunctionSpinner = false
    this.boxFunctions = res

    // if boxFuntions has getobjects, enable/render boxObject selection component
    if(!this.boxFunctions.find(fn => fn.__id == 'getobjects')){
      console.log("can NOT get boxObjects")
      this.canGetBoxObjects = false
      this.isBoxObjectConfigError = true
      this.boxObjectConfigError['error'] = {}
      this.boxObjectConfigError['error']['message'] = "'getobjects' not available in box functions"
    }else{
      console.log("can get boxObjects, will enable box object selection component")
      this.canGetBoxObjects = true
      this.isBoxObjectConfigError = false
      this.disableBoxObjectInput = false
    }
  }

  /**
   * fires when a boxObject is selected from boxObject autocomplete
   * saves the boxObjectId in this.selectedBoxObjectId
   * also calls the getAttributes() after boxObject is saved
   * @param boxObject
   */
   async boxObjectSelected(boxObject: any) {
    console.log("box object event", boxObject)
    console.log("box object selected", boxObject.__id)
    console.log("isOptionsToCollect", JSON.parse(JSON.stringify({isOptionsToCollect: this.isOptionsToCollect})))

    // check if config changed and keep flag
    if(boxObject.__id !== this.selectedBoxObjectId){
      this.boxObjectChanged = true
      this.isGetOptionsToCollect = false
      this.isOptionsToCollect = false
      this.isAtrributeReady = false
      this.saveDisabled = true
    }

    this.selectedBoxObjectId = boxObject.__id
    this.isBoxObjectSelected = true

    // update list panel title
    this.listPanelTitle = this.listPanelTitle ? this.listPanelTitle : `${this.selectedBoxName || "Starch"} - ${this.selectedBoxObjectId} - Listing`

    this.gettingObjFunSpinner = true
    let boxId = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
    let conType = this.selectedBoxId == 'starch' ? "token": '';
    let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken : this.selectedConnectionId;
    this.objectFuntions = await this.boxService.getBoxObjectFuntions(boxId, this.selectedBoxObjectId, conKey, conType)
    this.gettingObjFunSpinner = false
    console.log("box object functions received", this.objectFuntions)

    this.getFn = this.objectFuntions.find(fn => fn.__id == 'get')
    this.updateFn = this.objectFuntions.find(fn => fn.__id == 'update')
    if(this.updateFn){
      this.availableActions.forEach(action => {
        if(action.code == 'popup-edit' || action.code == 'popup-view-edit'){
          action.disabled = false
        }
      });
    }

    let getAttrFunction = this.boxFunctions.find(fn => fn.__id == 'getattributes')

    if (!this.getFn){
      console.log("get function not found")
      this.terminationError = true
      this.terminationErrorMessage = `'get' function not found on ${this.selectedBoxObjectId} of ${this.selectedBoxId}.`
      return

    } else if(!getAttrFunction){
      this.terminationError = true
      this.terminationErrorMessage = 'getting attributes not available on this connection and object'
      return

    } else if(this.getFn.options && !this.getFn.options.filter){
      this.panelMeta.loadInitialData = true
      this.lockLoadInitialData = true
      console.log("filter not available. locked loadInitialData to true, getFn:", this.getFn)
      this.terminationError = false
    }

    this.constructAttrOptions()
  }


  // calculates if input parameters needed for fetching attributes
  async constructAttrOptions(){

    console.log("[CONSTRUCT ATTR OPTIONS]")
    this.getAttrFn = this.boxFunctions.find(fn => fn.__id == 'getattributes')

    if(this.firstHit) {
      console.log("[CONSTRUCT ATTR OPTIONS] - if 1")
      this.attributeOptions = this.pageService.checkOptionsToCollect(this.getAttrFn)
      // keep availableInputParams in sync with attribute Options
      this.attributeOptions = this.attributeOptions.filter(op => !op.hidden)
      this.attributeOptions.forEach(op => {
        this.availableInputParams.options[op.__id || op.name] = op.value
        if(op?.hidden == true) this.attributeOptions.splice(this.attributeOptions.indexOf(op),1);
      })

      if(this.attributeOptions?.length){
        this.collectAttrOptions = true
      }else{
        await this.getAttributes();
        this.constructGetOptions()
      }

    } else if(!this.firstHit && (this.boxChanged || this.boxObjectChanged)){  // && (this.boxChanged || this.boxObjectChanged)
      console.log("[CONSTRUCT ATTR OPTIONS] - if 2")
      this.attributeOptions = this.pageService.checkOptionsToCollect(this.getAttrFn)
      // keep availableInputParams in sync with attribute Options
      this.attributeOptions.forEach(op => {
        this.availableInputParams.options[op.__id || op.name] = op.value
        if(op?.hidden == true) this.attributeOptions.splice(this.attributeOptions.indexOf(op),1);
      })

      if(this.attributeOptions?.length){
        this.collectAttrOptions = true
      }else{
        await this.getAttributes();
        this.constructGetOptions()
      }

    } else {  // default flow for 2nd hit when options available
      console.log("[CONSTRUCT ATTR OPTIONS] - if 3")
      this.attributeOptions?.forEach(op => {
        this.availableInputParams.options[op.__id || op.name] = op.value
        if(op?.hidden == true) this.attributeOptions.splice(this.attributeOptions.indexOf(op),1);
      })
      await this.getAttributes();
      if(this.attributeOptions?.length){
        this.collectAttrOptions = true
      }
      this.constructGetOptions()
    }
  }

  // calculates if input parameters needed for get fn
  constructGetOptions(){

    console.log("[CONSTRUCT GET OPTIONS]")
    this.getFn = this.objectFuntions.find(fn => fn.__id == 'get')

    if(this.firstHit){
      console.log("[CONSTRUCT GET OPTIONS] if (1)")
      this.getFnOptions = this.pageService.checkOptionsToCollect(this.getFn)
      this.getFnOptions = this.getFnOptions.filter(op => !op.hidden)
      if(!this.getFnOptions?.length) {
        this.getAttributes()
        return
      }
      this.getFnOptions?.forEach(el => {
        el.value = this.availableInputParams.options[el.__id] || el.defaultValue || ""
      })
      this.collectGetOptions = true
    } else if(!this.firstHit && (this.boxChanged || this.boxObjectChanged)){  // && (this.boxChanged || this.boxObjectChanged)
      console.log("[CONSTRUCT GET OPTIONS] if (2)")
      this.getFn = this.objectFuntions.find(fn => fn.__id == 'get')
      this.getFnOptions = this.pageService.checkOptionsToCollect(this.getFn)
      if(!this.getFnOptions?.length) {
        // this.constructActionOptions()
        this.getAttributes()
        return
      }
      this.getFnOptions?.forEach(el => {
        el.value = this.availableInputParams.options[el.__id] || el.defaultValue || ""
      })
      this.collectGetOptions = true

    } else{
      console.log("[CONSTRUCT GET OPTIONS] if (3)")
      if(!this.getFnOptions?.length){
        console.log("no options to collect, calling constructActionOptions() and return")
        // this.constructActionOptions()
        this.getAttributes()
        return
      }
      this.getFnOptions?.forEach(el => {
        // if some option already has value in availableInputParams, use it
        if(this.availableInputParams.options.hasOwnProperty(el.__id)){
          el.value = this.availableInputParams.options[el.__id]
        }else{ // if a new property, keep back in availableInputParams for further use in actionOptions
          this.availableInputParams.options[el.__id] = el.value
        }
      })
      console.log("availableInputParams", this.availableInputParams)

      console.log("making collectGetOptions true")
      this.collectGetOptions = true
      // this.constructActionOptions()
      this.getAttributes()
    }
  }


  /**
   * bound with event emitter to catch input parameters for getAttribute functions
   * @param attrInputParams
   */
  attrOptionInputsRecevied(attrInputParams){
    console.log("attribute input params received", attrInputParams)
    if(!attrInputParams.options) return
    Object.keys(attrInputParams.options).forEach(optionId => {
      this.attributeOptions.forEach(attrOp => {
        if(attrOp.__id == optionId){
          attrOp.value = attrInputParams.options[optionId]
        }
      })
      // update availableInputParams for further use
      this.availableInputParams['options'][optionId] = attrInputParams.options[optionId]
    })
    console.log("availableInputParams updated", this.availableInputParams)
    console.log("attribute options", this.attributeOptions)
  }

  /**
   * bound with event emitter to catch input parameters for other getFn functions
   * @param getFnInputParams
   */
  getOptionInputsRecevied(getFnInputParams){
    console.log("getFn input params received", getFnInputParams)
    if(!getFnInputParams?.options) return
    Object.keys(getFnInputParams.options).forEach(optionId => {
      this.getFnOptions.forEach(getOption => {
        if(getOption.__id == optionId){
          getOption.value = getFnInputParams.options[optionId]
        }
      })
    })
    console.log("get options", this.getFnOptions)
  }

  async getAttributes() {
    this.attributeSpinner = true
    console.log("attribute spinner", this.attributeSpinner ? true : false)
    console.log("attributeOptions", this.attributeOptions);

    let boxId = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
    let conType = this.selectedBoxId == 'starch' ? "token": '';
    let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken : this.selectedConnectionId;

    let payloadOptions:any = {}
    if(this.selectedBoxId == 'starch'){
      payloadOptions = {
        relationObject: "starch_relationship"
      }
    }
    let response: any
    try{
      response = await this.boxService.getAttributes(conKey, boxId, this.selectedBoxObjectId, this.attributeOptions, null, conType, payloadOptions)
    }catch(err){
      console.error("list-panel-dialog component: getAttributes()", err)
      this.isAttributeError = true
      this.attributeError = JSON.stringify(err)
      this.openSnackBar({ snackBarMessage: "Could not fetch attributes" }, err)
    }

    this.boxObjectAttributes = response.result
    this.isAtrributeReady = true;
    console.log("attributes received:", this.boxObjectAttributes)

    this.attributeSpinner = false
    console.log("turned false", this.attributeSpinner)

    this.excludeSelected()

    // this.boxObjectAttributes.forEach(a => {
    //   a['fieldType'] = 'attribute'
    //   a['widgetType'] = 'label'
    // })

    this.boxObjectAttributes.forEach(a => {
      let foundMatch = false; 
      this.listAttributes.forEach(attr => {
          if (attr.__id == a.__id) {
              if (attr['fieldType']) {
                  a['fieldType'] = attr['fieldType'];
              } else {
                  a['fieldType'] = 'attribute';
              }
  
              if (attr['widgetType']) {
                  a['widgetType'] = attr['widgetType'];
              } else {
                  a['widgetType'] = 'label';
              }
              foundMatch = true;
          }
      });
      if (!foundMatch) {
          a['fieldType'] = 'attribute';
          a['widgetType'] = 'label';
      }
    

      //if existing configuration is not yet touched, show existing attributes
      //else intellisense attribute
      if(!(this.boxChanged || this.boxObjectChanged)){
        // this.listAttributes = JSON.parse(JSON.stringify(this.panelMeta.listAttributes))
        this.listAttributes = [...this.panelMeta.listAttributes]
        this.isReadyToSave = true
        this.saveDisabled = false;
        this.filter = this.panelMeta?.filter || {}

        if(this.filter.filterItems && this.filter.filterItems.length > 0) {
          for(var i = 0; i < this.filter.filterItems.length; i ++){
            this.selectFilterAttributeForm.addControl("form" + i, new UntypedFormControl())
            let valueMap = {
              __id: this.filter.filterItems[i].attribute,
              dataType: this.filter.filterItems[i].dataType || "string"
            }
            this.selectFilterAttributeForm.patchValue({[`form${i}`]: valueMap});
          }
        }
      } else{
        // auto add edit button at beginning
        this.listAttributes = []
        this.newListIcon('edit')

        // intellisense attributes
        let intelliSensed = this.WidgetUtilityService.intelliSenseAttribute(this.boxObjectAttributes, 5)
        intelliSensed.forEach(attr => {
          attr['sortEnabled'] = false
          attr['show_hide'] = false
          attr['fieldType'] = 'attribute'
          attr['widgetType'] = 'label'
          attr['isColumnSelected'] = true
        });


        // merge intellisensed with list attributes
        this.listAttributes = this.listAttributes.concat(intelliSensed)
        console.log("after intellisensed addition: ", this.listAttributes)

        this.findPrimaryAttribute()

        // auto add delete icon at end
        this.newListIcon('delete')

        //check if at least one list attribute found
        if(this.listAttributes.findIndex(attr => attr.fieldType == 'attribute') !== -1){
          this.isReadyToSave = true
          this.saveDisabled = false
        } else {
          this.isAttributeError = true
          this.attributeError = 'no attributes found for selected box'
        }
      }
      // this.attributeSpinner = false
    })
  }


  /**
   * creates a new attributes list by excluding already selected from all attributes
   */
  excludeSelected() {
    let arr = []
    arr = JSON.parse(JSON.stringify(this.listAttributes))

    let unselected: any = []
    this.boxObjectAttributes.forEach(attr => {
      // console.log("dealing ", attr)
      let selected: boolean = false
      arr.forEach(listAttr => {
        if (listAttr.__id == attr.__id) {
          // console.log("found selected")
          selected = true
        }
      });
      if (!selected) {
        unselected.push(attr)
      }
    })
    // console.log("unselected array:", unselected)
    this.unselectedListAttributes = unselected
  }

  addStatusFieldInListAttributes(event: any){
    console.log("new list attribute:", event.option.value)
    let attr = event.option.value
    // attr['sortEnabled'] = false
    // attr['show_hide'] = false
    attr['fieldType'] = 'attribute'
    // attr['widgetType'] = 'label'
    attr['statusOnly'] = true
    this.listAttributes.push(attr)
    // this.selectStatusFieldControl.patchValue('')
  }

  attributeSelectionChanged(attributes: any[]){
    let customAttributes = this.listAttributes.filter(attr => attr.fieldType != 'attribute')
    this.listAttributes = attributes.filter(attr => attr.fieldType == 'attribute')
    customAttributes.forEach(attr => this.listAttributes.push(attr))
    this.panelMeta['listAttributes'] = JSON.parse(JSON.stringify(this.listAttributes || []))
  }

  customAttributeMenuOpened(){
    this.customAttribute = {
      widgetType: "label",
      columnName: "",
      sortEnabled: false,
      show_hide: false,
      value: "",
      dataType: 'NA',
      isColumnSelected: true
    }
  }

  saveCustomAttribute(){

    this.isReadyToSave = true;

    if (this.customAttribute.widgetType == 'attribute') {
      this.customAttribute.fieldType = "attribute";
      this.customAttribute.widgetType= "label";
      this.customAttribute.dataType= "string"
    } else if(this.customAttribute.widgetType == 'serial') {
      // this.customAttribute.fieldType = "serial";
      let serialObj = {
        "widgetType": "label",
        "columnName": this.customAttribute.columnName,
        "sortEnabled": false,
        "show_hide": false,
        "value": "__serial__",
        "dataType": "NA",
        "isColumnSelected": true,
        "name": "__serial__",
        "__id": "___serial__",
        "fieldType": "label"
      }
      this.listAttributes.splice(0, 0, serialObj);
      return;
    } else if (this.customAttribute.widgetType == 'button') {
      this.customAttribute.fieldType = this.customAttribute.widgetType;
      this.customAttribute.widgetType = "button";
      this.customAttribute.dataType = "string"
      this.customAttribute.name = this.customAttribute.value;
      this.customAttribute.__id = Date.now() + '_' + this.customAttribute.name;
    } else {
      this.customAttribute.name = this.customAttribute.value;
      this.customAttribute.__id = Date.now() + '_' + this.customAttribute.name;
      this.customAttribute.fieldType = this.customAttribute.widgetType;
    }
    this.listAttributes.push(this.customAttribute);
  }

  customeWidgetTypeSelected(widgetType: string){
    this.customAttribute.widgetType = widgetType;
  }


  // newListLabel(label: string){
  //   console.log("list label selected:", label)
  //   let attr = {
  //     __id: Date.now() + '_' + label,
  //     name: label,
  //     value: label,
  //     columnName: label ? label : '',
  //     sortEnabled: false,
  //     show_hide: false,
  //     fieldType: 'label',
  //     dataType: 'NA',
  //     widgetType: 'label'
  //   }
  //   this.listAttributes.push(attr)
  // }

  newListIcon(iconName: string){
    console.log("list icon selected:", iconName)
    let attr = {
      __id: Date.now() + '_' + iconName,
      name: iconName,
      value: iconName,
      columnName: iconName ?  iconName : '',
      sortEnabled: false,
      show_hide: false,
      fieldType: 'icon',
      dataType: 'NA',
      widgetType: 'icon'
    }
    this.listAttributes.push(attr)
  }

  removeListAttribute(event: any, i: number) {
    // console.log("before removal:", this.listAttributes)
    // console.log("list attribute to be removed:", this.listAttributes[i])
    this.listAttributes.splice(i, 1)
    console.log("list attributes after removal", this.listAttributes)
    this.excludeSelected()
    if (!this.listAttributes.length) {
      this.isReadyToSave = false
    }
  }

  showHideToggleChanged(event: MatCheckboxChange, index: any): void {
    console.log("show/hide toggle changed", event, "at index", index)
    if (event.checked) {
      this.listAttributes[index].show_hide = true
    }
    else {
      this.listAttributes[index].show_hide = false
    }
  }

  loadInitialDataChanged(event) {
    console.log("load initial data toggle", event)
    this.panelMeta.loadInitialData = event.checked
  }

  paginationChanged(event) {
    console.log("Pagination enablement changed", event)
    this.panelMeta.paginationEnabled = event.checked;
    if(!this.panelMeta.paginationType){
      this.panelMeta.paginationType = "pagebypage";
    }
  }

  additionalAttributeToggleChanged(event: MatCheckboxChange): void {
    console.log("showHideToggle Changed", event.checked)
    this.panelMeta.additionalAttributesAllowed = event.checked
  }

  sortableChanged(event: MatCheckboxChange, index: any) {
    console.log("sortable changed", event, "at index", index)
    if (event.checked) {
      this.listAttributes[index].sortEnabled = true
    }
    else {
      this.listAttributes[index].sortEnabled = false
    }
  }

  removeDuplicates(a) {
    return Array.from(new Set(a));
  }

  filterEmptyString(attr) {
    return attr !== ''
  }

  saveConfig() {
    // console.log("Save config hit at list panel", this.panelMeta)

    this.checkAttributeOptionsProvided()
    this.checkGetOptionsProvided()
    this.checkGroupingField()
    // this.validateSort()

    console.log("groupingFieldError", this.groupingFieldError)
    console.log("attributeOptionsError", this.attributeOptionsError)
    console.log("getOptionsError", this.getOptionsError)
    // console.log("sortSettingsError", this.sortSettingsError)
    if(this.groupingFieldError) return
    if(this.attributeOptionsError) return
    if(this.panelMeta.loadInitialData && this.getOptionsError) return
    // if(this.sortSettingsError) return

    this.panelMeta.connectionId = this.selectedConnectionId
    this.panelMeta.boxId = this.selectedBoxId
    this.panelMeta.boxName = this.selectedBoxName
    this.panelMeta.boxObjectId = this.selectedBoxObjectId
    this.panelMeta.boxConfigToken = this.boxConfigToken
    this.panelMeta.attributeOptions = this.attributeOptions
    this.panelMeta.getFnOptions = this.getFnOptions
    this.panelMeta.listPanelTitle = this.listPanelTitle
    // this.panelMeta.noDataMessage = this.noDataMessage
    this.panelMeta.viewTypes = JSON.parse(JSON.stringify(this.viewTypes))
    this.panelMeta.primaryAttribute = this.boxObjectAttributes?.find(attr => attr.primary)
    this.panelMeta.filter = this.filter
    this.panelMeta.sort = this.sort
    this.panelMeta.listAttributes = this.listAttributes
    this.panelMeta.listWidgetSet = this.listPanelService.generateWidgetSet(this.listAttributes, this.panelMetaOld, this.selectedConnectionId, this.selectedBoxId, this.selectedBoxObjectId)
    this.panelMeta['getFn'] = this.getFn

    this.panelMeta.noDataMessage = this.panelMeta.noDataMessage || "There is no data to be shown here";
    this.data = this.panelMeta
    console.log("save config in list panel dialog: will return data:", this.data)

    // clear list panel data cache
    if(this.pageService.listPanelDataCache[this.panelMeta.id]){
      delete this.pageService.listPanelDataCache[this.panelMeta.id]
    }

    this.dialogRef.close(JSON.parse(JSON.stringify(this.data)))
  }

  checkGetOptionsProvided(){
    if(this.panelMeta.loadInitialData){
      // check if all required options provided for get function
      this.getFnOptions?.forEach((option: any) => {
        if(option.required && !option['value']){
          console.log("value for ", option.name, "not provided")
          this.getOptionsError = true
          this.faultyGetOptions.push(option)
        }
      })
      if(!this.faultyGetOptions.length){
        this.getOptionsError = false
        return
      }
      window.document.getElementById('getFnOptions').scrollIntoView()
      console.log("missing get options", this.faultyGetOptions)
    }
  }

  checkGroupingField(){
    console.log("view types", this.viewTypes)
    if(!this.viewTypes.userCanChoose && this.viewTypes.defaultView !== 'board'){
      this.groupingFieldError = false
      console.log("grouping field error", this.groupingFieldError)
      return false
    }
    if(this.viewTypes.defaultView == 'board' || this.viewTypes.board.userCanChoose){
      if(!this.viewTypes.boardStatusColumn || !this.viewTypes.boardStatusColumn.__id){
        this.groupingFieldError = true
        return true
      }
    }
    this.groupingFieldError = false
    return false
  }

  checkAttributeOptionsProvided(){
    // check if all required getAttributes options provided
    this.faultyAttrOptions = []
    this.faultyGetOptions = []
    this.attributeOptions?.forEach((option: any) => {
      if(option.required && !option['value']){
        console.log("value for ", option.name, "not provided")
        this.attributeOptionsError = true
        this.faultyAttrOptions.push(option)
      }
    })
    console.log("missing attribute options", this.faultyAttrOptions)
    if(!this.faultyAttrOptions.length) this.attributeOptionsError = false
    console.log("attribute error", this.attributeOptionsError)
  }


  // generateWidgetSet(){
  //   console.log("generate widget set hit", this.listAttributes)
  //   let newWidgetSet: any = {}
  //   let oldWidgetSet: any = this.panelMetaOld.listWidgetSet || {}

  //   let oldWidgetSetValidity: boolean = true
  //   // console.log("connectionId: old", this.panelMetaOld.connectionId, "new", this.selectedConnectionId)
  //   // console.log("boxId: old", this.panelMetaOld.boxId, "new", this.selectedBoxId)
  //   // console.log("boxObjectId: old", this.panelMetaOld.boxObjectId, "new", this.selectedBoxObjectId)
  //   if(
  //     this.selectedConnectionId !== this.panelMetaOld.connectionId ||
  //     this.selectedBoxId !== this.panelMetaOld.boxId ||
  //     this.selectedBoxObjectId !== this.panelMetaOld.boxObjectId
  //   ){
  //     oldWidgetSetValidity = false
  //     console.log("oldWidgetSet invalid")
  //   }

  //   for (let i = 0; i < this.listAttributes.length; i++) {
  //     let widgetType
  //     console.log("attribute under watch", this.listAttributes[i])
  //     if (!this.listAttributes[i].isDrillDown) {
  //       console.log("not drill down")
  //       widgetType = this.listAttributes[i].widgetType == 'NA' ? this.listAttributes[i].fieldType : this.listAttributes[i].widgetType;
  //       const attrId = this.listAttributes[i].__id;
  //       if (
  //         oldWidgetSetValidity &&
  //         oldWidgetSet[attrId] &&
  //         oldWidgetSet[attrId].type == widgetType
  //       ) {
  //         // console.log("copying old widget prototype", this.listAttributes[i])
  //         newWidgetSet[attrId] = oldWidgetSet[attrId]
  //       } else {
  //         // console.log("creating new widget prototype", this.listAttributes[i])
  //         newWidgetSet[attrId] = WidgetManager.getWidget(widgetType || 'label')
  //       }
  //     } else if (this.listAttributes[i].isDrillDown && this.listAttributes[i].nestedProperties.length) {
  //       console.log("drill down")
  //       let attr = this.listAttributes[i]
  //       for (let i = 0; i < attr.nestedProperties.length; i++) {
  //         const nestedProp = attr.nestedProperties[i];
  //         widgetType = nestedProp.widgetType || 'label'
  //         let nestedId = attr.__id + '.' + nestedProp.path

  //         if (
  //           oldWidgetSetValidity &&
  //           oldWidgetSet[nestedId] &&
  //           oldWidgetSet[nestedId].type == widgetType
  //         ) {
  //           // console.log("copying old widget prototype", this.listAttributes[i])
  //           newWidgetSet[nestedId] = oldWidgetSet[nestedId]
  //         } else {
  //           // console.log("creating new widget prototype", this.listAttributes[i])
  //           newWidgetSet[nestedId] = WidgetManager.getWidget(nestedProp.widgetType || 'label')
  //         }
  //       }
  //     }
  //   };
  //   console.log("widget set prepared", newWidgetSet)
  //   return newWidgetSet
  // }


  optionsIconClicked(event: any, index: any) {
    console.log("list attribute's option icon clicked at index", index)
  }

  boxSelectionError(event){
    console.log("box selection error", event)
    this.isBoxConfigError = true
    this.boxConfigError = event
  }

  boxObjectSelectionError(event){
    console.log("box object selection error", event)
    this.isBoxObjectConfigError = true
    this.boxObjectConfigError = event
  }

  openEventPanel(event: any, attr: any){
    if(!attr.eventType) attr.eventType = 'navigation'
    this.panelOpenedFor = attr.__id;
    this.navigationPanelOpened = !this.navigationPanelOpened
    this.onEventChange(event, attr)
  }

  dataSourceTypeChanged(event){
    this.panelMeta.dataSource = event.value;
    if(!this.panelMeta?.customAttributes) this.panelMeta.customAttributes = [];
  }

  onEventChange(event: any, attr: any){
    if(!attr.eventType || attr.eventType == 'navigation') this.openNavigationPanel(event, attr);
    else if (attr.eventType == 'action') {
      if(!attr.actionConfig) {
        attr.actionConfig = {
          "event": "click",
          "action": "application",
          "actionMap": {
            "mapping": [],
            successMessage: `The ${this.selectedBoxObjectId} action is successfull`
          }
        }
      }
    } else if (attr.eventType.startsWith('popup')) {
      console.log("popup event chosen", event, attr)
      let replacementIndex = this.listAttributes.findIndex(a => a.__id == attr.__id)
      this.listAttributes[replacementIndex] = attr
    }
    

    this.getExecutableActions()
  }

  updateFnInputsReceived(attr: any, data: any){
    // console.log("update fn inputs received", data)
    // check if update function needs additional inputs
    let updateFnOptions = this.pageService.checkOptionsToCollect(this.updateFn)
    updateFnOptions = updateFnOptions.filter(op => !op.hidden)

    // console.log("update fn options", JSON.parse(JSON.stringify(updateFnOptions)))
    // console.log("data", JSON.parse(JSON.stringify(data)))
    updateFnOptions.forEach(ip => {
      if(data.options.hasOwnProperty(ip.__id)){
        ip['value'] = data.options?.[ip.__id]
      }
    })
    // console.log("after adding vals from data", JSON.parse(JSON.stringify(updateFnOptions)))

    let attrIndex = this.listAttributes.findIndex(a => a.__id == attr.__id)
    this.listAttributes[attrIndex]['updateFnOptions'] = updateFnOptions
  }

  async getExecutableActions(){
    this.boxFunctionSpinner = true
    let boxId = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
    let conType = this.selectedBoxId == 'starch' ? "token": '';
    let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken : this.selectedConnectionId;
    let attributeActions = await this.boxService.getBoxObjectFuntions(boxId, this.selectedBoxObjectId, conKey, conType);
    this.attributeActions = [];
    attributeActions.forEach(element => {
      if(element.crudType == 'D')this.attributeActions.push(element)
    });
    this.boxFunctionSpinner = false
  }

  onActionSelected(funAction:any, attr){
    console.log(funAction, attr)
    this.boxFunctionSpinner = true

    attr.actionConfig.actionMap["action"] = `${this.selectedBoxObjectId}/${funAction.__id}`;
    if(this.selectedBoxId == 'starch'){
      attr.actionConfig.actionMap.baseMap = {
        "box_id": this.panelMeta.baseMap?.box_id,
        "base_id":  this.panelMeta.baseMap?.base_id
      }
      attr.actionConfig.actionMap["boxId"] = "starch";
      attr.actionConfig.actionMap["boxConfigToken"]= this.boxConfigToken
    } else {
      attr.actionConfig.actionMap["boxId"] = this.selectedBoxId;
      attr.actionConfig.actionMap.connection =  this.panelMeta.connectionId || this.selectedConnectionId;
    }

    attr.actionConfig.actionMap["actionMode"] = funAction.__id;

    let mapping = [];
    funAction.input?.list.forEach(element => {
      let obj:any = {};
      obj["appField"] = funAction?.input[element];
      obj.mappingType = "sourceField"
      let sourceField = this.boxObjectAttributes.filter((e) => e?.primary);
      obj.sourceField = sourceField[0]
      console.log("sourceField", sourceField)
      mapping.push(obj)
    });
    attr.actionConfig.actionMap.mapping = mapping;
    console.log("mapping", mapping)
    let replacementIndex = this.listAttributes.findIndex(attribute => attribute.__id == attr.__id)
    this.listAttributes[replacementIndex] = attr;
    this.boxFunctionSpinner = false;
  }

  openNavigationPanel(event: any, attr: any){
    console.log("will open navigation settings for ", attr)
    console.log("getFnOptions", this.getFnOptions)

    // create boxConfig
    this.boxConfigSetup = {
      boxId: this.selectedBoxId,
      boxObjectId: this.selectedBoxObjectId,
      connectionId: this.selectedConnectionId,
      boxName: this.selectedBoxName,
      attributeOptions: this.attributeOptions,
      getFnOptions: this.getFnOptions,
      getFn: this.isGetOptionsToCollect ? this.getFn : null
    }


  }

  saveNavigationSettings(attr){
    console.log("navivation settings set in attribute(in listPanelDialog)", attr)
    this.navigationPanelOpened = false
    this.panelOpenedFor = undefined
    let replacementIndex = this.listAttributes.findIndex(attribute => attribute.__id == attr.__id)
    this.listAttributes[replacementIndex] = attr
    console.log("updated in list attributes list", this.listAttributes)
  }

  // popupParametersReceived(attr: any, data: any){
  //   console.log("popup filter params received", data)
  //   this.navigationPanelOpened = false
  //   this.panelOpenedFor = undefined
  //   attr['popupParams'] = JSON.parse(JSON.stringify(attr.params))
  //   delete attr['params']
  //   delete attr['actionConfig']
  //   delete attr['navigationSettings']
  //   let replacementIndex = this.listAttributes.findIndex(attribute => attribute.__id == attr.__id)
  //   this.listAttributes[replacementIndex] = attr
  //   console.log("updated in list attributes list", this.listAttributes)
  // }

  openWidgetMenu(attr: any){
    console.log("open widget menu hit for", attr)
  }

  widgetMenuClosed(event: any){
    console.log("widget menu closed", event)
  }

  widgetTypeSelected(attrIndex: any, widgetType: string, nestedPropIndex?: any){
    if(!this.listAttributes[attrIndex].isDrillDown){
      this.listAttributes[attrIndex]['widgetType'] = widgetType
      console.log("modified list attributes", this.listAttributes)
      if(widgetType == 'image'){
        this.listAttributes[attrIndex]['imageDimension'] = this.DEFAULT_IMAGE_SIZE
      }
      this.boxObjectAttributes.forEach(attr => {
        if(attr.__id == this.listAttributes[attrIndex].__id){
          attr['widgetType'] = widgetType
        }
      });
    }else{
      this.listAttributes[attrIndex]['nestedProperties'][nestedPropIndex]['widgetType'] = widgetType
    }
  }

  attributeOptionChanged(event, index){
    this.attributeOptions[index]['value'] = event.srcElement.value
  }
  getOptionChanged(event, index){
    this.getFnOptions[index]['value'] = event.srcElement.value
    console.log("this.getFnOptions", this.getFnOptions)
  }

  defaultViewChanged(event, el: HTMLElement){
    console.log("default view type changed", event.value)
    this.viewTypes.defaultView = event.value

    this.viewTypes[event.value]['userCanChoose'] = true
    if(event.value == 'board'){
      this.scroll(el);
    }
    this.checkGroupingField()
  }

  userViewTypesChanged(event, view, el: HTMLElement){
    console.log("user view types changed", event.checked, "for", view)
    this.viewTypes[view]['userCanChoose'] = event.checked
    if(view == 'board' && this.viewTypes[view]['userCanChoose'] == true ){
      this.scroll(el)
    }
    this.checkGroupingField()
  }

  scroll(el: HTMLElement){
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth' });
    }, 10);
  }

  userCanChooseChanged(event){
    console.log("user can choose changed", event)
    this.viewTypes.userCanChoose = event.checked
    this.checkGroupingField()
  }

  usercanSelectFieldsChanged(event){
    console.log("user can select fields changed", event)
    this.viewTypes.canSelectFields = event.checked
    this.checkGroupingField()
  }

  hideTitleChanged(event){
    console.log("hide title changed", event)
    this.panelMeta['hideTitle'] = event.checked
  }

  boardGroupColumnChanged(event){
    console.log("board view grouping column changed", event)
    this.viewTypes.boardStatusColumn = event.option.value
    console.log("view types now", this.viewTypes)
    this.groupingFieldError = false
  }

  primaryAttributeSelected(event){
    console.log("primary attr selected", event)
    this.primaryAttribute = event.option.value

    console.log("view types now", this.viewTypes)
  }

  findPrimaryAttribute(){
    let i = this.listAttributes.findIndex(attr => attr.primary)
    if(i > -1){
      this.primaryAttributeName = this.listAttributes[i].name
      this.isPrimaryExists = true
      return true
    }else{
      return false
    }
  }

  filterToggleChanged(toggleValue: any){
    console.log(toggleValue)
    this.filter.filterEnabled = toggleValue.checked;
    if(!this.filter.filterItems || this.filter.filterItems.length == 0){
      this.addFilterItem();
    }
  }

  addFilterItem(){
    this.filter.filterItems.push({
      attribute: '',
      operator: '=',
      value: '',
      dataType: '',
      filterType: 'static_filter'
    })
    this.selectFilterAttributeForm.addControl("form" + (this.filter.filterItems.length - 1), new UntypedFormControl())
  }

  onSelectFilterType(filter, obj){
    filter.filterType = obj.value;
  }


  filterAttrSelected(event: any, filter, i) {

    console.log("new filter event:", event)
    filter.attribute = event.option.value.__id;
    filter.dataType = event.option.value.dataType;

    console.log("this.selectFilterAttributeForm", this.selectFilterAttributeForm)
    // this.newFilter.attribute = event.option.value.__id
    // this.newFilter['dataType'] = event.option.value.dataType
  }

  removeFilter(i: number){
    this.filter.filterItems.splice(i, 1)
    console.log("this.filter", this.filter)
  }

  // filterTypeSelected(event: any){
  //   this.newFilter.value = ''
  //   console.log("filter type selected", event)

  //   if(event.value == 'page_filter'){
  //     this.dataModel = this.pageService.dataModelSub.value
  //     console.log("data model", this.dataModel)
  //     let connections: string[] = Object.keys(this.dataModel)
  //     connections = connections.filter(conn => conn !== '_pageId')
  //     console.log('connections', connections)
  //     connections.forEach((conn: any) => {
  //       let boxObjects: any = Object.keys(this.dataModel[conn])
  //       console.log('boxObjects', boxObjects)
  //       boxObjects.forEach((boxObj: any) => {
  //         let attributes: any = Object.keys(this.dataModel[conn][boxObj])
  //         console.log('attributes', attributes)
  //         attributes.forEach((attr: any) => {
  //           this.pageFilters.push({
  //             connection: conn,
  //             boxObject: boxObj,
  //             attribute: attr
  //           })
  //         })
  //       })
  //     })
  //     console.log("page filters generated", this.pageFilters)
  //   }
  // }

  pageFilterSelected(event, filter){
    console.log("dynamic filter value selected", event)
    filter.value = event.value
  }

  navigationFilterInput(attributeName: string, filter){
    filter.value = attributeName
  }

  setSameNavigationAttribute(event, filter){
    console.log("setSameNavigationAttribute", event)
    filter.value = event.checked ? filter.attribute : ''
  }

  defaultListSizeChanged(size: number){
    console.log("default list size changed", size)
    this.panelMeta['defaultListSize'] = size
    console.log("new panel meta", this.panelMeta)
  }

  drillDownToggle(i){
    if(!this.listAttributes[i].isDrillDown) {
      this.listAttributes[i]['isDrillDown'] = true
    } else {
      this.listAttributes[i].isDrillDown = false
    }
  }

  isColumnSelectedToggle(i){
    console.log("isColumnSelected toggle for ", this.listAttributes[i])
    if(!this.listAttributes[i].isColumnSelected) this.listAttributes[i]['isColumnSelected'] = true
    else this.listAttributes[i].isColumnSelected = false
  }

  nestedValueWidgetSelected(widgetType, i, j){
    let attr = this.listAttributes[i]
    console.log(widgetType, "for", attr.__id, ".", attr['nestedProperties']?.[j]?.['path'])
    attr.nestedProperties[j]['widgetType'] = widgetType
    console.log("attr", attr)
  }

  addNestedproperty(i){
    let attr = this.listAttributes[i]
    if(!attr.nestedProperties){
      attr['nestedProperties'] = []
    }
    attr.nestedProperties.push({
      path: '',
      widgetType: 'label',
      fieldType: 'attribute'
    })
    console.log("attr now", attr)
  }

  deleteNestedProperty(i, j){
    let attr = this.listAttributes[i]
    attr.nestedProperties.splice(j, 1)
    console.log("attr now", attr)
  }

  cardClickEventToggle(event: any){
    if(!this.panelMeta['navigationSettings']){
      this.panelMeta['navigationSettings'] = {
        enabled: event.checked,
        navFilterAttributes: [],
        pageCode: '',
        tooltip: '',
        type: 'internal'
      }
    }else{
      this.panelMeta.navigationSettings['enabled'] = event.checked
    }
  }

  saveCardNavigationSettings(event){
    console.log("navivation settings set in attribute(in listPanelDialog)", event)
    // this.navSettingsExpansion.close()
    this.panelMeta.navigationSettings = event.navigationSettings
  }


  onChangeIcon(event){
    console.log("icon changed", event)
    this.customAttribute.value = event
  }

  /**
   * panelMeta.summary: {
   *    enabled: boolean,
   *    charts: [chart widgets]
   * }
   * @param event 
   */
  summaryToggled(event){
    console.log("summary toggle changed", event)
    if(!this.panelMeta.summary){
      this.panelMeta['summary'] = { enabled: true }
    } else {
      this.panelMeta.summary.enabled = event.checked
    }
    
    // when no chart present, add a chart
    // if(this.panelMeta.summary?.enabled && !this.panelMeta.summary?.charts?.length){
    //   this.addSummaryChart()
    // }
    console.log("summary charts", this.panelMeta.summary.charts)
  }

  addSummaryChart(chartType?: string){
    if(!this.panelMeta.summary?.charts?.length){
      this.panelMeta.summary['charts'] = []
    }
    let chart = WidgetManager.getWidget('chart|' + (chartType || 'bar'))
    console.log("chart widget created", chart)
    console.log("panelMeta", JSON.parse(JSON.stringify(this.panelMeta)))

    // initialize chart data source (box configuration same as the list panel)
    let dataConfig = {
      connectionId: this.panelMeta.connectionId || this.selectedConnectionId,
      boxId: this.panelMeta.boxId || this.selectedBoxId,
      boxObjectId: this.panelMeta.boxObjectId || this.selectedBoxObjectId,
      boxName: this.panelMeta.boxName || this.selectedBoxName,
      getFnOptions: this.panelMeta.getFnOptions || this.getFnOptions,
      attributeOptions: this.panelMeta.attributeOptions || this.attributeOptions,
    }
    chart = this.chartService.setChartBoxConfig(chart, dataConfig)
    chart.config.chartType.value = chartType || "bar"
    chart.config.dataSource.isBoxSupportGroup = this.isBoxSupportGroup
    if(chart.config.chartType.value !== 'scorecard'){
      chart.config.dataSource.dimensions = [{}]
    }

    this.panelMeta.summary.charts.push(chart)
    this.chartConfigOpenedIndex = this.panelMeta.summary.charts.length - 1
  }

  deleteSummaryChart(i: number){
    this.panelMeta.summary.charts.splice(i, 1)
  }

  saveFilterConfig(data: any){
    console.log("filter received", data)
    this.filter = data

    // if template is involved, keep a templateSignature besides filter.value for reference (if filter.value gets replaced)
    if(this.filter?.filterEnabled && this.filter.filterItems?.length) {
      this.filter.filterItems.forEach(filter => {
        if(filter.value.startsWith("${") && filter.value.endsWith("}")){

          // this variable should not be replaced when template is handled
          //result of template replacement should be written in filter.value and used
          filter.templateSignature = filter.value 
        }
      })
    }
  }

  /**
   * summary chart data source
   * @param data 
   * @param i 
   */
  newChartDataSource(data, i){
    console.log("new chart data source for ", i, "is", data)
    this.panelMeta.summary.charts[i] = data
  }

  openSnackBar(snackBarObj: any, error: any){
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        title: error?.error?.error?.name || error.message || error || '',
        description: error?.error?.error?.message || error?.error?.error || error?.error?.message || '',
        recommendation: snackBarObj?.snackBarRecommendation || '',
        message: snackBarObj.snackBarMessage,
        iconname: snackBarObj.snackBarIcon,
        isError: true
      },
      duration: snackBarObj.snackBarDuration || undefined,
      horizontalPosition: 'end',
    });
  }

}
