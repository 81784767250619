<!-- <spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner> -->
<!-- <div *ngIf="runSpinner">
  <spinner [type]="'skeleton'" [selfSpin]="true" [skeletonPresetCode]="'form-loader'"></spinner>
</div> -->
<div class="formCover"  *ngIf="!runSpinner"
[ngStyle]="margin? {'margin-left': margin.left + '%', 'margin-right': margin.right + '%'} : {'margin-left': '0%', 'margin-right': '0%'}"
>
  <div fxLayout fxLayoutAlign="space-between center" style="margin: 0 0 .5rem 0;">
    <div fxFlex="10%" fxLayout fxLayoutAlign="center center"></div>
    <div fxFlex="80%" fxLayout fxLayoutAlign="center center" class="form-panel-title">
      <div *ngIf="!panelMeta.hideTitle">{{ panelMeta.formPanelTitle || "Form Panel" }}</div>
    </div>
    <div fxFlex="5%" fxLayout fxLayoutAlign="center center"></div>
    <div fxFlex="5%" fxLayout fxLayoutAlign="center center">
      <mat-icon *ngIf="builderMode" style="cursor:pointer" class="listActionIcon" (click)="openSettings()">settings</mat-icon>
    </div>
  </div>

</div>
