<div class="header" fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title> What do you want to list?</div>
  <button #closeButton mat-button mat-dialog-close style="cursor: pointer;">
    <mat-icon>close</mat-icon>
  </button>
</div>

<spinner *ngIf="attributeSpinner || boxFunctionSpinner || gettingObjFunSpinner" [center]="true" [selfSpin]="true" [type]="'ball-triangle-path'"></spinner>
<!-- <spinner *ngIf="!firstHit && !isAtrributeReady" [center]="true" [selfSpin]="true" [type]="'ball-triangle-path'"></spinner> -->

<mat-dialog-content>

  <div fxLayout fxLayoutAlign="start center">
    <mat-radio-group (change)="dataSourceTypeChanged($event)" [value]="panelMeta.dataSource" color="primary">
      <mat-radio-button class="radioItem" value="app">App Data from Connection/ Starch</mat-radio-button>
      <mat-radio-button class="radioItem" value="custom">Custom Data</mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="!panelMeta.dataSource || panelMeta.dataSource === 'app'"  [class.fade]="!firstHit && !isAtrributeReady">
    <app-app-selection
      [existingBox]="selectedBoxId"
      [config]="{isSupportStarch: true}"
      (selectedBox)="appSelected($event)"
    >
    </app-app-selection>

    <app-connection-selection
      *ngIf="isAppSelected && selectedBoxId !== 'starch'"
      [existingCon]="selectedConnectionId"
      [config]="{ box_id: selectedBoxId, focus: true }"
      (selectedConnection)="boxSelected($event)"
    ></app-connection-selection>

    <base-selection *ngIf="isAppSelected && selectedBoxId === 'starch'" [existingBase]="panelMeta.baseId"
    (selectedBase)="baseSelected($event)"></base-selection>

    <app-select-box-object
      *ngIf="canGetBoxObjects"
      [boxId]="getSelectedBoxId()"
      [connectionId]="getSelectedConnectionId(selectedBoxId)"
      [boxConfigToken]="boxConfigToken"
      [oldValue]="panelMeta.boxObjectId"
      [disableBoxObjectInput]="disableBoxObjectInput"
      (boxObjectInput)="boxObjectSelected($event)"
      (boxObjectSelectionError)="boxObjectSelectionError($event)"
    >
    </app-select-box-object>
    
    <small *ngIf="isBoxObjectConfigError" style="color: red;">{{ boxObjectConfigError.error.message | json }}</small>

    <div *ngIf="collectAttrOptions">
      <div style="height: 1rem;"></div>
      <div class="subheading">Input params to fetch attributes</div>
      <!-- <div style="height: .5rem;"></div> -->
      <shared-parameter-inputs
        [action]="getAttrFn"
        [existingInputs]="availableInputParams"
        [isBoxObject]="canGetBoxObjects"
        [isOnlyOptions]="true"
        (inputParameter)="attrOptionInputsRecevied($event)"
      ></shared-parameter-inputs>

      <button
        mat-stroked-button
        color="primary"
        (click)="constructGetOptions()"
      ><mat-icon>east</mat-icon>
      </button>
    </div>


    <div *ngIf="collectGetOptions">
      <div style="height: 1rem;"></div>
      <div class="subheading">Input params to get record</div>
      <!-- <div style="height: .5rem;"></div> -->
      <shared-parameter-inputs
        [action]="getFn"
        [existingInputs]="availableInputParams"
        [isOnlyOptions]="true"
        (inputParameter)="getOptionInputsRecevied($event)"
      ></shared-parameter-inputs>

      <button
        mat-stroked-button
        color="primary"
        (click)="getAttributes()"
      ><mat-icon>east</mat-icon>
      </button>
    </div>
  </div>

  <div class="attributeSelection" *ngIf="isAtrributeReady || panelMeta?.dataSource === 'custom'">

    <!-- <div style="height: 1.5rem;"></div> -->
    <div fxLayout style="min-width: 100%;" fxLayoutAlign="start center">
      <mat-form-field fxFlex="50">
        <mat-label>List Panel Title</mat-label>
        <input matInput [disabled]="panelMeta.hideTitle" [(ngModel)]="listPanelTitle">
      </mat-form-field>
      <div fxFlex="15">
        <mat-checkbox color="warn" [checked]="panelMeta.hideTitle"
        (change)="hideTitleChanged($event)">Hide title</mat-checkbox>
      </div>
    </div>

    <div
      class="listattributeheader"
      style="font-size: 1.2rem; font-weight: bold;"
    >
      List {{attributeDisplayName | titlecase}}s
    </div>


    <div >

    <div class="attributes-table">
      <div class="example-list" cdkDropList (cdkDropListDropped)="drop($event)">

        <div class="table-header" fxLayout="row" fxLayoutAlign="space-between center">
          <span class="th" fxFlex="3"></span>
          <span class="th" fxFlex="2">#</span>
          <span class="th" fxFlex="4"></span>
          <span class="th" fxFlex="24" fxLayout="row" fxLayoutAlign="start center">{{attributeDisplayName | titlecase}}</span>
          <span class="th" fxFlex="20">Widget</span>
          <span class="th" fxFlex="8">Sortable</span>
          <!-- <span class="th" fxFlex="12">Allow hide</span> -->
          <span class="th" fxFlex="20" style="text-align: center;">On Click</span>
          <span class="th" fxFlex="7"></span>
          <span class="th" fxFlex="5"></span>
        </div>

        <div
          class="example-box smallText"
          style="cursor: default;"
          *ngFor="let attr of listAttributes;
          let i = index"
          cdkDrag
          (mouseenter)="hoveredIndex = i"
          (mouseleave)="hoveredIndex = -1"
          fxLayout="row wrap" fxLayoutAlign="space-between center"
        >
          <!-- DRAG INDICATOR -->
          <div class="reorder td" fxFlex="3">
            <mat-icon cdkDragHandle style="cursor: move; opacity: 60%;" [style.visibility]="hoveredIndex === i ? 'visible' : 'hidden'">drag_indicator</mat-icon>
          </div>

          <!-- SERIAL NUMBER -->
          <div class="serial-number td" fxFlex="2">{{ i + 1 }}</div>

          <!-- FIELD TYPE ICON -->
          <div class="type-icon td" fxFlex="4" style="opacity: 60%;" *ngIf="attr.fieldType === 'attribute'"><mat-icon>label_important</mat-icon></div>
          <div class="type-icon td" fxFlex="4" style="opacity: 60%;" *ngIf="attr.fieldType === 'label'"><mat-icon>notes</mat-icon></div>
          <div class="type-icon td" fxFlex="4" style="opacity: 60%;" *ngIf="attr.fieldType === 'icon'"><mat-icon>emoji_symbols</mat-icon></div>
          <div class="type-icon td" fxFlex="4" style="opacity: 60%;" *ngIf="attr.fieldType === 'button'"><mat-icon>crop_7_5</mat-icon></div>

          <!-- ATTRIBUTE NAME -->
          <div class="attribute-id td" fxFlex="24" fxLayout fxLayoutAlign="start center">{{attr.name}}</div>

          <!-- WIDGET TYPE -->
          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="20">
            <button
              *ngIf="attr.fieldType === 'attribute'"
              matTooltip="chose widget type to display the value"
              mat-button
              fxFlexFill
              class="widgetSelectionButton"
              [matMenuTriggerFor]="menu"
              aria-label="Widget selection menu"
            >
              <div fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                <mat-icon style="opacity: 60%;" *ngIf="widgetMap[attr.widgetType]">{{ widgetMap[attr.widgetType].icon || '' }}</mat-icon>
                <span *ngIf="attr.fieldType === 'attribute'" class="selectedWidget">{{ widgetMap[attr.widgetType].name }}</span>
                <mat-icon style="opacity: 60%;">arrow_drop_down</mat-icon>
              </div>
            </button>

            <mat-menu #menu="matMenu" yPosition="below" (closed)="widgetMenuClosed($event)">
              <div *ngFor="let widType of widgetMap.list;">
                <button mat-menu-item (click)="widgetTypeSelected(i, widType)">
                  <mat-icon>{{widgetMap[widType].icon}}</mat-icon>
                  <span>{{widgetMap[widType].name}}</span>
                </button>
              </div>
            </mat-menu>
          </div>

          <!-- SORTABLE -->
          <div class="sortable td" fxFlex="8">
            <mat-checkbox
            *ngIf="attr.sortable"
            [checked]="attr['sortEnabled']"
              (change)="sortableChanged($event, i)"
            >
            </mat-checkbox>
          </div>

          <!-- ALLOW HIDE -->
          <!-- TODO -->
          <!-- <div class="allow-hide td" fxFlex="12">
            <mat-checkbox
              disabled
              [checked]="attr['show_hide']"
              (change)="showHideToggleChanged($event, i)"
            >
            </mat-checkbox>
          </div> -->

          <!-- NAVIGATION -->
          <div fxLayout="row" class="td" (click)="openEventPanel($event, attr)" style="cursor: pointer" fxLayoutAlign="start center" fxLayoutGap=".5rem" fxFlex="20">
            <div  fxFLex="10" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon [matTooltip]="'Setup On Click Action for ' + attr.name" style="opacity: 60%">arrow_drop_down</mat-icon>
            </div>

            <ng-container *ngIf="attr.navigationSettings && attr.navigationSettings.enabled; else elseTemplate">
              <div class="linkedPage" [matTooltip]="attr.navigationSettings.pageCode || attr.navigationSettings.linkUrl" fxFLex="80">
                {{ attr.navigationSettings.pageCode || attr.navigationSettings.linkUrl.substring(0, 15) + '...' }}
              </div>
            </ng-container>
            <ng-template #elseTemplate>
              <div [matTooltip]="'Setup On Click Action for ' + attr.name" fxFLex="90">{{attr.actionConfig?.action || 'none'}}</div>
            </ng-template>
          </div>

          <!-- MENU -->
          <div fxFlex="7" class="td">
            <button mat-icon-button #attributeMenu="matMenuTrigger" [matMenuTriggerFor]="menu2">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu2="matMenu">
              <button mat-menu-item (click)="drillDownToggle(i)">
                <mat-icon color="{{attr.isDrillDown?'primary':''}}">{{attr.isDrillDown?'check_box':'check_box_outline_blank'}}</mat-icon>
                Drill down
              </button>
              <button mat-menu-item (click)="isColumnSelectedToggle(i)">
                <mat-icon color="{{attr.isColumnSelected?'primary':''}}">{{attr.isColumnSelected?'check_box':'check_box_outline_blank'}}</mat-icon>
                Show on load
              </button>
            </mat-menu>
          </div>

          <!-- DELETE ICON -->
          <div class="remove td" fxFlex="5">
            <button mat-icon-button (click)="removeListAttribute($event, i)">
              <mat-icon style="opacity: 60%;">delete</mat-icon>
            </button>
          </div>

          <!-- DRILL DOWN CONFIGURATION -->
          <mat-card appearance="outlined" *ngIf="attr.isDrillDown" fxFlex=100 fxLayout="column" fxLayoutAlign="start start">
            <div fxLayoutAlign="end center" style="width: 100%;">
              <button mat-flat-button (click)="drillDownToggle(i)">
                <mat-icon color="{{attr.isDrillDown?'primary':''}}">{{attr.isDrillDown?'check_box':'check_box_outline_blank'}}</mat-icon>
                Drill down
              </button>
            </div>
            <div fxLayoutAlign="flex-start center" style="width: 100%;">
              <div fxFlex="10"></div>
              <div 
                fxFlex="90" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap=".5rem"
                cdkDropList [cdkDropListData]="attr.nestedProperties" (cdkDropListDropped)="reorderNestedFieldsForAttr($event, i)"
              >
                <div style="height: 1rem" fxFlex="100"></div>
                <div fxFlex="100" fxLayoutAlign="start center">
                  <div fxLayoutAlign="start center">
                    <span style="color: #444">Nested property access for</span>
                    <span style="color: black">&nbsp;{{attr.__id}}</span></div>
                  </div>
                  <div style="font-size: .8rem; color: #777; font-style: italic; ">path may be separated by dot for deep nesting</div>
                <div style="height: .5rem" fxFlex="100"></div>

                <div *ngFor="let nestedProperty of attr.nestedProperties; let j = index" cdkDrag style="width: 100%;">
                  <div fxFlex="100" fxLayoutAlign="space-between center">

                    <div class="reorder td" fxFlex="5">
                      <mat-icon cdkDragHandle style="cursor: move; opacity: 60%;">drag_indicator</mat-icon>
                    </div>

                    <mat-form-field fxFlex="32" style="margin-bottom: -1.25em">
                      <input matInput placeholder="Nested value path" [(ngModel)]="nestedProperty.path">
                    </mat-form-field>
                    <mat-form-field fxFlex="32" class="no-margin-bottom">
                      <input 
                        matInput 
                        placeholder="Display name for convenience" 
                        [ngModel]="nestedProperty.headerTitle || nestedProperty.path"
                        (input)="nestedProperty.headerTitle = $event.target.value"
                      >
                    </mat-form-field>

                    <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center">
                      <button
                        *ngIf="nestedProperty.fieldType === 'attribute'"
                        matTooltip="chose widget type to display the value"
                        mat-button
                        fxFlexFill
                        class="widgetSelectionButton"
                        [matMenuTriggerFor]="menu"
                        aria-label="Widget selection menu"
                      >
                        <div fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                          <mat-icon style="opacity: 60%;" *ngIf="widgetMap[nestedProperty.widgetType]">{{ widgetMap[nestedProperty.widgetType].icon || '' }}</mat-icon>
                          <span *ngIf="nestedProperty.fieldType === 'attribute'" class="selectedWidget">{{ widgetMap[nestedProperty.widgetType].name }}</span>
                          <mat-icon style="opacity: 60%;">arrow_drop_down</mat-icon>
                        </div>
                      </button>

                      <mat-menu #menu="matMenu" yPosition="below" (closed)="widgetMenuClosed($event)">
                        <div *ngFor="let widType of widgetMap.list;">
                          <button mat-menu-item (click)="widgetTypeSelected(i, widType, j)">
                            <mat-icon>{{widgetMap[widType].icon}}</mat-icon>
                            <span>{{widgetMap[widType].name}}</span>
                          </button>
                        </div>
                      </mat-menu>
                    </div>

                    <mat-icon fxFlex="10" (click)="deleteNestedProperty(i, j)" style="color: #444">delete</mat-icon>
                  </div>
                </div>

                <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                  <button mat-stroked-button (click)="addNestedproperty(i)">
                    <mat-icon>add</mat-icon>
                    Access another property
                  </button>
                </div>

              </div>
            </div>
          </mat-card>

          <!-- ON CLICK EVENT CONFIGURATION -->
          <div style="padding-left: 10px;" fxFlex="100" fxLayout="row" *ngIf="navigationPanelOpened && panelOpenedFor == attr.__id">
            <mat-form-field >
              <mat-label>Select event</mat-label>
              <mat-select (selectionChange)="onEventChange($event, attr)" [(ngModel)] = "attr.eventType">
                <ng-container *ngFor="let option of availableActions">
                  <mat-option [value]="option.code" *ngIf="!option.disabled">
                    {{ option.name }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxFlex="100" fxLayout="column" fxLayoutAlign="start start" *ngIf="navigationPanelOpened && panelOpenedFor == attr.__id && attr.eventType === 'action'">
            <div  class="fullWidth" fxLayout="column" fxLayoutAlign="start start">
              <div fxLayout="row" style="padding-left: 10px;">
                <mat-form-field *ngIf="attributeActions">
                  <mat-label>Select Action</mat-label>
                  <mat-select [(ngModel)]="attr['actionConfig']['actionMap'].actionMode">
                    <mat-option [value]="action.__id" *ngFor="let action of attributeActions" (click)="onActionSelected(action, attr)">
                      {{ action.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div style="padding-left: 10px; margin-bottom: 10px;" class="fullWidth" fxLayoutAlign="center center" *ngIf="attr['actionConfig']">
                <mat-form-field class="fullWidth" style="margin-bottom: -24px;">
                  <mat-label>Tooltip</mat-label>
                  <input matInput [(ngModel)]="attr['actionConfig'].tooltip">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div fxFlex="100" *ngIf="navigationPanelOpened && panelOpenedFor === attr.__id && attr.eventType === 'navigation'">
            <app-navigation-panel
              [attr]="attr"
              [boxConfig]="boxConfigSetup"
              [attributeList]="boxObjectAttributes"
              [connectionId]="selectedConnectionId"
              [boxObjectId]="selectedBoxObjectId"
              [panelMeta]="panelMeta"
              (emitCollapsePanel)="saveNavigationSettings($event)"
            >
            </app-navigation-panel>
          </div>

          <div fxFlex="100" *ngIf="navigationPanelOpened && panelOpenedFor === attr.__id && attr.eventType?.startsWith('popup')">
            
            <ng-container>
              <shared-parameter-inputs
                style="width: 100%;"
                [action]="updateFn"
                [allActions]="objectFuntions"
                [isSupportQuery]="false"
                [isBoxObject]="true"
                [isOnlyOptions]="true"
                [optionalInputs]="{ options: { object: selectedBoxObjectId } }"
                [connection]="selectedConnection"
                (inputParameter)="updateFnInputsReceived(attr, $event)"
                ></shared-parameter-inputs>
              </ng-container>
              <!-- [getAttributesFunction]="getAttrFn" -->
              <!-- [isOnlyOptions]="true" -->

            <!-- <ng-container *ngIf="!panelMeta.primaryAttribute">
              <div style="text-align:left; color: #666">Specify field(s) to identify your row uniquely (recommended)</div>
              <app-navigation-panel
                [attr]="attr"
                [boxConfig]="boxConfigSetup"
                [attributeList]="boxObjectAttributes"
                [connectionId]="selectedConnectionId"
                [boxObjectId]="selectedBoxObjectId"
                [panelMeta]="panelMeta"
                [collectOnlyParams]="true"
                (emitCollapsePanel)="popupParametersReceived(attr, $event)"
              >
              </app-navigation-panel>
            </ng-container> -->
              <!-- <button mat-stroked-button (click)="saveNavigationSettings(attr)">Save</button> -->
          </div>
        </div>
      </div>
    </div>

    <!-- CHOSE FIELD TYPE TO ADD -->
    <div class="fieldTypeWrap" style="margin-top: .5rem;" fxLayout fxLayoutAlign="start center" fxLayoutGap=".5rem">

      <button mat-raised-button matTooltip="Choose fields" [matMenuTriggerFor]="menu" (click)="selectAttributeMenuOpened()">
          <mat-icon class="iconInButton">checklist</mat-icon>
          <span class="addFieldText">Choose {{attributeDisplayName | titlecase}}</span>
      </button>
      <!-- (opened)="isAttributeMenuOpen = true" (closed)="isAttributeMenuOpen = false" -->
      <mat-menu #menu="matMenu">
        <ng-container *ngIf="selectAttributeMenuOpened">
          <app-attribute-selection
            [boxObjectAttributes]="boxObjectAttributes"
            [listAttributes]="listAttributes"
            [supportApplyButton]="true"
            (listAttributeSelected)="attributeSelectionChanged($event)"
        ></app-attribute-selection>
        </ng-container>
      </mat-menu>

      <button mat-raised-button [matMenuTriggerFor]="matMenuCustomAttribute" class="fieldType" (click)="customAttributeMenuOpened()">
        <mat-icon class="iconInButton">notes</mat-icon>
        <span class="addFieldText">Add Custom {{attributeDisplayName | titlecase}}</span>
      </button>

      <mat-menu #matMenuCustomAttribute="matMenu" style="padding: 0px;">

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" (click)="$event.stopPropagation()">
          <div fxLayout="column" fxFlex="30" style="margin-left: 12px;"> Widget type: </div>
          <div  fxLayout="column" fxFlex="40" >
          <button
            matTooltip="chose widget type to display the value"
            mat-button
            fxFlexFill
            class="widgetSelectionButton"
            [matMenuTriggerFor]="menuWidget"
            aria-label="Widget selection menu"
          >
            <div fxLayoutAlign="space-between center" class="smallText">
              <mat-icon style="opacity: 60%;" *ngIf="customWidgetMap[customAttribute.widgetType]">{{ customWidgetMap[customAttribute.widgetType].icon || '' }}</mat-icon>
              <span *ngIf="customWidgetMap[customAttribute.widgetType]" class="selectedWidget">{{ customWidgetMap[customAttribute.widgetType].name }}</span>
              <mat-icon style="opacity: 60%;">arrow_drop_down</mat-icon>
            </div>
          </button></div>

          <mat-menu #menuWidget="matMenu" yPosition="below" (closed)="widgetMenuClosed($event)">
            <div *ngFor="let widType of customWidgetMap.list;" (click)="$event.stopPropagation()">
              <button mat-menu-item (click)="customeWidgetTypeSelected(widType)" >
                <mat-icon>{{customWidgetMap[widType].icon}}</mat-icon>
                <span>{{customWidgetMap[widType].name}}</span>
              </button>
            </div>
          </mat-menu>

          <div fxLayout="column" fxFlex="30"> </div>
        </div>
        <div style="height: 1rem"> </div>
        <div style="padding: 0px 10px 0px 10px;" fxLayout fxLayoutAlign="center center" (click)="$event.stopPropagation()">

          <div *ngIf="customAttribute.widgetType === 'label'" fxLayout="row wrap" fxLayoutAlign="space-around center">
            <form (keydown.tab)="$event.stopPropagation()">
              <mat-form-field floatLabel="never" class="full-width" style="margin-top: -16px;" fxFlex="50">
                <input matInput placeholder="Value" [(ngModel)]="customAttribute.value">
              </mat-form-field>
              <mat-form-field floatLabel="never" class="full-width" style="margin-top: -16px;" fxFlex="50">
                <input matInput placeholder="Column header" [(ngModel)]="customAttribute.columnName">
              </mat-form-field>
            </form>
          </div>

          <div *ngIf="customAttribute.widgetType === 'icon'" fxLayout="row wrap" fxLayoutAlign="space-around center">
            <form>
              <app-googleicon-picker class="form-field-padding" [label]="'Choose icon'"
                [value]="customAttribute.value" (iconSelectionChanged)="onChangeIcon($event)" appearance="outline">
              </app-googleicon-picker>
              <mat-form-field appearance="outline" class="full-width" fxFlex="50">
                <input matInput placeholder="Column header" [(ngModel)]="customAttribute.columnName">
              </mat-form-field>
            </form>
          </div>

          <div *ngIf="customAttribute.widgetType === 'button'" fxLayout="row wrap" fxLayoutAlign="space-around center">
            <form>
              <mat-form-field appearance="outline" class="full-width" fxFlex="50">
                <input matInput placeholder="Column header" [(ngModel)]="customAttribute.columnName">
              </mat-form-field>
              <mat-form-field appearance="outline" class="full-width" fxFlex="50">
                <input matInput placeholder="Button text" [(ngModel)]="customAttribute.value">
              </mat-form-field>
            </form>
          </div>

          <div *ngIf="customAttribute.widgetType === 'attribute'" fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field class="noPaddingBottom" fxFlex="50">
                <input matInput type="text" placeholder="Id" [(ngModel)]="customAttribute.__id" style="width: 100%">
            </mat-form-field>
            <mat-form-field class="noPaddingBottom" fxFlex="50">
                <input matInput type="text" placeholder="Name" [(ngModel)]="customAttribute.name" style="width: 100%">
            </mat-form-field>
          </div>

          <div *ngIf="customAttribute.widgetType === 'serial'" fxLayout="row wrap" fxLayoutAlign="space-around center">
            <mat-form-field class="noPaddingBottom" fxFlex="100">
              <input matInput type="text" placeholder="Name" [(ngModel)]="customAttribute.columnName" style="width: 100%">
            </mat-form-field>
          </div>
        </div>

        <div fxLayoutAlign="end center">
          <button mat-button color="primary" > Cancel </button>
          <button mat-button color="primary" (click)="saveCustomAttribute()"> Add </button>
        </div>
      </mat-menu>
    </div>

    <div style="margin-top: 1rem;" fxLayout fxLayoutAlign="start center" fxLayoutGap="1rem">
      <div>
        <mat-checkbox color="primary" class="additionalAttributeCheckbox" [checked]="viewTypes.canSelectFields"
        (change)="usercanSelectFieldsChanged($event)">Allow field selection</mat-checkbox>
      </div>

      <div>
        <mat-checkbox color="primary" class="additionalAttributeCheckbox" [(ngModel)]="panelMeta.userFilterEnabled">
          Allow filtering by user
        </mat-checkbox>
      </div>

      <div>
        <mat-checkbox color="primary" class="additionalAttributeCheckbox" [(ngModel)]="panelMeta.userSortEnabled">
          Allow sort by user
        </mat-checkbox>
      </div>
    </div>


    <!-- TODO -->
    <!-- <div>
      <mat-checkbox class="additionalAttributeCheckbox" [checked]="panelMeta.additionalAttributesAllowed" (change)="additionalAttributeToggleChanged($event)">allow additional attributes</mat-checkbox>
    </div> -->

     <!-- CHOSE FIELD TYPE TO ADD -->
     <div class="fieldTypeWrap" style="margin-top: .5rem;" fxLayout fxLayoutAlign="start center" >


      <!-- <div>
        <mat-checkbox color="primary" class="additionalAttributeCheckbox" [checked]=""
        (change)="">Allow resize</mat-checkbox>
      </div>
      <div>
        <mat-checkbox color="primary" class="additionalAttributeCheckbox" [checked]=""
        (change)="">Allow rearrange</mat-checkbox>
      </div> -->

    </div>

    <div style="height: 1rem;"></div>
    <app-filter-config  *ngIf="!panelMeta?.dataSource || panelMeta?.dataSource === 'app'"
      [filter]="filter"
      [boxObjectAttributes]="boxObjectAttributes"
      [pageMeta]="pageMeta"
      [enableSecurity]="false"
      (selectedFilter)="saveFilterConfig($event)"
    >
    </app-filter-config>

    <div style="height: 1.5rem;"></div>
    <app-sort-config *ngIf="!panelMeta?.dataSource || panelMeta?.dataSource === 'app'"
      [sort]="sort"
      [boxObjectAttributes]="boxObjectAttributes"
      [enableSecurity]="false"
      (selectedSort)="sortChanged($event)"
    >
    </app-sort-config>

   </div>

    <!-- <div style="height: 1rem;"></div> -->
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-checkbox class="subheading" [checked]="panelMeta.loadInitialData" (change)="loadInitialDataChanged($event)" color="primary">Load Initial Data</mat-checkbox>.
      <!-- <div style="width: 100%;"> -->&nbsp;&nbsp; Page Size: &nbsp;
        <mat-form-field class="full-width" fxFlex="10">
          <!-- <mat-label>Page Size</mat-label> -->
          <input class="pageSizeInput" #sizeInput matInput type="number" [value]="panelMeta.defaultListSize || 10" (input)="defaultListSizeChanged(sizeInput.value)">
        </mat-form-field>
      <!-- </div> -->
    </div>


    <!-- <div style="height: 1rem;"></div> -->
    <div fxLayout="row wrap">
      <div>
        <mat-slide-toggle
        color="primary"
        class="subheading"
        labelPosition="after"
        [checked]="panelMeta.paginationEnabled"
        (change)="paginationChanged($event)"
        >
          Allow Pagination
        </mat-slide-toggle>
      </div>
      &nbsp;
      <div *ngIf="panelMeta.paginationEnabled">
        <mat-radio-group [(ngModel)]="panelMeta.paginationType" aria-label="Select an pagination type">
          <mat-radio-button value="pagebypage">Page By Page</mat-radio-button> &nbsp;
          <mat-radio-button value="singlepage">Single Page</mat-radio-button>
        </mat-radio-group>
        <!-- &nbsp;<mat-checkbox *ngIf="panelMeta.paginationType == 'singlepage'" class="subheading"color="primary">Auto scroll</mat-checkbox> -->
      </div>
    </div>



    <div style="height: 1.5rem;"></div>
    <div fxLayout style="min-width: 100%;">
      <mat-form-field fxFlex="50">
        <mat-icon color="primary" matPrefix>{{viewTypesDisp[viewTypes.defaultView].icon}}</mat-icon>
        <mat-label>Default View</mat-label>

        <mat-select [(value)]="viewTypes.defaultView" (selectionChange)="defaultViewChanged($event, boardGroup)">
          <mat-option *ngFor="let view of viewTypes.views" [value]="viewTypes[view].name">
            {{ viewTypes[view].displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <mat-slide-toggle
      color="primary"
      class="subheading"
      labelPosition="after"
      [checked]="viewTypes.userCanChoose"
      (change)="userCanChooseChanged($event)"
    >
    Allow user to switch view
    </mat-slide-toggle>

    <div *ngIf="viewTypes.userCanChoose">
      <div style="height: 1rem;"></div>
      <div fxLayout="row" fxLayoutAlign="start">
        <div *ngFor="let view of viewTypes.views" fxLayout="row" fxLayoutAlign="center center" >
          <mat-checkbox class="subheading inlineCheckbox" [checked]="viewTypes[view].userCanChoose" [disabled]="view == viewTypes.defaultView" (change)="userViewTypesChanged($event, view, boardGroup)" color="primary">
            <div fxLayoutAlign="center center">
              <mat-icon>{{viewTypesDisp[view]['icon']}}</mat-icon><span>{{ viewTypes[view]['displayName'] }}</span>
            </div>
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div>
      <div #BoardView *ngIf="(!viewTypes.userCanChoose && viewTypes.defaultView === 'board') || (viewTypes.userCanChoose && viewTypes['board'].userCanChoose)">
        <div style="height: 1.5rem;"></div>
        <div fxLayout style="min-width: 100%;">
          <mat-form-field fxFlex="50">
            <mat-label>Select Grouping Attribute for Board View</mat-label>
            <input
              #groupingAttributeInput
              type="text"
              aria-label="attribute autocomplete"
              matInput
              [formControl]="selectStatusFieldControl"
              [matAutocomplete]="statusauto"
            >
            <button *ngIf="groupingAttributeInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="selectStatusFieldControl.patchValue('')">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #statusauto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="boardGroupColumnChanged($event); ">
              <mat-option *ngFor="let attr of filteredStatusAttributes | async" [value]="attr">
                <span>{{attr.__id}}</span>
              </mat-option>
            </mat-autocomplete>
            <mat-hint *ngIf="groupingFieldError" style="color: red;">please select a grouping attribute</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <span #boardGroup></span>
    </div>

    <!-- collect options for get function -->
    <div *ngIf="isAtrributeReady && isGetOptionsToCollect && panelMeta.loadInitialData" id="getFnOptions" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="1rem">
      <div style="height: 1.5rem;"></div>
      <div class="subheading">Please provide the following inputs to fetch records</div>
      <ng-container *ngFor="let option of getFnOptions; let i = index">
        <mat-form-field>
          <mat-label>{{ option.name }}</mat-label>
          <input matInput (input)="getOptionChanged($event, i)" [required]="option.required" [value]="option.value">
          <mat-hint style="color: #3f51b5;">Input type: {{ option.dataType }}</mat-hint>
        </mat-form-field>
      </ng-container>
    </div>


    <div style="height: 1.5rem;"></div>
    <div fxLayoutAlign="start center" fxLayoutGap="1rem">
      <mat-slide-toggle
        color="primary"
        class="subheading"
        labelPosition="after"
        [checked]="panelMeta.summary?.enabled"
        (change)="summaryToggled($event)"
      >Summary view</mat-slide-toggle>
      <div><mat-icon [matTooltip]="summaryViewIntro">information</mat-icon></div>
    </div>
    <div *ngIf="panelMeta.summary?.enabled">
      <div fxLayoutAlign="start center" fxLayoutGap="1rem">
        <button class="toolButton" mat-stroked-button color="primary" (click)="addSummaryChart('pie')">
          <mat-icon aria-hidden="false" aria-label="button icon">pie_chart</mat-icon>
          <span class="buttonText">Pie Chart</span>
        </button>
        <button class="toolButton" mat-stroked-button color="primary" (click)="addSummaryChart('bar')">
          <mat-icon aria-hidden="false" aria-label="button icon">bar_chart</mat-icon>
          <span class="buttonText">Bar Chart</span>
        </button>
        <button class="toolButton" mat-stroked-button color="primary" (click)="addSummaryChart('line')">
          <mat-icon aria-hidden="false" aria-label="button icon">show_chart</mat-icon>
          <span class="buttonText">Line Chart</span>
        </button>
        <button class="toolButton" mat-stroked-button color="primary" (click)="addSummaryChart('gauge')">
          <mat-icon aria-hidden="false" aria-label="button icon">data_exploration</mat-icon>
          <span class="buttonText">Gauge Chart</span>
        </button>
        <button class="toolButton" mat-stroked-button color="primary" (click)="addSummaryChart('scorecard')">
          <mat-icon aria-hidden="false" aria-label="button icon">score</mat-icon>
          <span class="buttonText">Score card</span>
        </button>
      </div>
      <div style="height:1rem"></div>

      <mat-accordion  cdkDropList (cdkDropListDropped)="dropSummaryChart($event)" *ngIf="panelMeta.summary?.enabled && panelMeta.summary?.charts?.length">
        <div *ngFor="let chartWidget of panelMeta.summary?.charts; let i = index" cdkDrag fxLayoutAlign="space-between center">
          
          <div class="reorder" fxFlex="5">
            <mat-icon cdkDragHandle style="cursor: move; opacity: 60%;">drag_indicator</mat-icon>
          </div>

          <mat-expansion-panel fxFlex="85" [expanded]="chartConfigOpenedIndex === i">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>{{ CHART_ICON[chartWidget.config.chartType.value] }}</mat-icon>
                {{ chartWidget.config.chartType.value + " summary chart" }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              <chart-dynamic-attribute-config
                [widgetMeta]="chartWidget"
                [attributes]="boxObjectAttributes"
                (newWidgetMeta)="newChartDataSource($event, i)"
              >
              </chart-dynamic-attribute-config>
            </p>
          </mat-expansion-panel>

          <button fxFlex="10" mat-icon-button (click)="deleteSummaryChart(i)"><mat-icon>delete</mat-icon></button>
        </div>
      </mat-accordion>
    </div>


    <div style="height: 1.5rem;"></div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field fxFlex="100" >
        <mat-label>No Data Message</mat-label>
        <textarea matInput
          [(ngModel)]="panelMeta.noDataMessage"
         ></textarea>
      </mat-form-field>
    </div>
  </div>


  <!-- <mat-expansion-panel
    *ngIf="isAtrributeReady && ((viewTypes.userCanChoose && viewTypes['card'].userCanChoose) || viewTypes.defaultView == 'card')"
    (opened)="cardNavigationPanelOpened = true"
    (closed)="cardNavigationPanelOpened = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Configure click event on whole card
      </mat-panel-title>
      <mat-panel-description>
        <mat-icon *ngIf="panelMeta.navigationSettings && panelMeta.navigationSettings.enabled">check</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
  </mat-expansion-panel> -->
  <div style="padding: 0 1rem" *ngIf="isAtrributeReady && ((viewTypes.userCanChoose && viewTypes['card'].userCanChoose) || viewTypes.defaultView === 'card')">
    <mat-slide-toggle
      color="primary"
      class="subheading"
      labelPosition="after"
      [checked]="panelMeta.navigationSettings && panelMeta.navigationSettings.enabled"
      (change)="cardClickEventToggle($event)"
    >
      Enabled click event on whole card
    </mat-slide-toggle>
    <div *ngIf="panelMeta.navigationSettings && panelMeta.navigationSettings.enabled">
      <p style="font-size: 0.9rem; color: #444"><i>If enabled, field specific click actions are masked</i></p>
      <app-navigation-panel
        [attr]="panelMeta"
        [boxConfig]="boxConfigSetup"
        [attributeList]="boxObjectAttributes"
        [connectionId]="selectedConnectionId"
        [boxObjectId]="selectedBoxObjectId"
        [panelMeta]="panelMeta"
        [bypassSave]="true"
        (emitCollapsePanel)="saveCardNavigationSettings($event)"
      >
      </app-navigation-panel>
    </div>
  </div>


</mat-dialog-content>


<div *ngIf="attributeOptionsError || (getOptionsError && panelMeta.loadInitialData)">
  <div style="color: red;">Please provide values for the option(s)</div>
  <span *ngFor="let option of faultyAttrOptions">
    {{option.name + ", "}}
  </span>
  <span *ngFor="let option of faultyGetOptions">
    {{option.name + ", "}}
  </span>
</div>

<!---------------------------- SAVE CONFIG BUTTON ----------------------------------->
<div class="sticky" fxLayout fxLayoutAlign="center center" fxFlexFill>
  <button
    *ngIf="isReadyToSave || panelMeta?.dataSource === 'custom'"
    [disabled]="saveDisabled || groupingFieldError"
    fxLayout
    fxLayoutAlign="center center"
    mat-raised-button
    color="primary"
    (click)="saveConfig()"
  >
    {{ firstHit? 'Create list panel' : 'Save configuration' }}
  </button>
</div>
