import { Component, HostListener, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { PageService } from '../../services/page-service.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MetaService } from '../../services/meta-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.css']
})
export class CanvasComponent implements OnInit, OnDestroy {
  imageCopied: boolean = false;
  isFooterInFocus: boolean = false
  pageStructure: any;
  builderMode: boolean = false
  fragmentSubscription: any
  pageChangeNotifierSub: any
  showFooter: boolean = false;
  // @Input() pageNames = []
  // @Input() bloomCode = '';
  private reader: FileReader = new FileReader();
  copiedFile:any;
  isBrowser: boolean;
  constructor(
    private metaService: MetaService,
    public pageService: PageService,
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(DOCUMENT) public document: Document,
    private route: ActivatedRoute
  ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
      this.document.addEventListener("paste", async(e) => {
        if (!e.clipboardData.files.length) {
          return;
        }
        const file = e.clipboardData.files[0];
        if(this.pageService.isPointerInCanvas && this.pageService.isPastedOnce){
          this.imageCopied = true;
          this.copiedFile = file;
          // this.pageService.createCopiedContent(file, "image");
        }
      })
  }
  // comment

  ngOnInit(): void {
    this.fragmentSubscription = this.route.fragment.subscribe((fragment: string) => {
      this.builderMode = (fragment == 'edit') ? true : false
    });

    this.pageChangeNotifierSub = this.pageService.$pageChangeNotifier.subscribe(isChange => {
      if(isChange) this.showFooter = false
    })
    // this.document.addEventListener("paste", this.pasteFromListener.bind(this))
   }

  ngOnDestroy() {
    this.fragmentSubscription.unsubscribe()
    this.pageChangeNotifierSub.unsubscribe()
    // this.document.removeEventListener('paste', this.pasteFromListener.bind(this));
  }

  mouseover(){
    this.pageService.isPointerInCanvas = true;
  }

  mouseout(){
    this.pageService.isPointerInCanvas = false;
    // this.pageService.resetCopiedData()
  }

  @HostListener('window:keydown',['$event'])
  async onKeyPress($event: KeyboardEvent) {
    if(($event.ctrlKey || $event.metaKey) && $event.key == "c" && !$event.repeat){
      console.log('CTRL + C', $event.repeat);
      this.imageCopied = false;
      if(this.pageService.isPointerInCanvas) {
        this.pageService.copyWidget();
        this.pageService.isPastedOnce = false;
        this.pageService.isToolCutted = false;
      }
    }

    if(($event.ctrlKey || $event.metaKey) && $event.key == "x" && !$event.repeat){
      console.log('CTRL + x', $event.repeat);
      this.imageCopied = false;
      this.pageService.isPastedOnce = false;
      if(this.pageService.isPointerInCanvas) this.pageService.cutWidget();
    }

    if(($event.ctrlKey || $event.metaKey) && $event.key == "v" && !$event.repeat){

      let content = await navigator.clipboard.readText();
      if(this.pageService.isPointerInCanvas && this.pageService.isPastedOnce && content){
        this.imageCopied = false;
        this.pageService.createCopiedContent(content);
        console.log("in coppied clip")
      } else if(this.pageService.isPointerInCanvas && !this.imageCopied) {
        this.imageCopied = false;
        this.pageService.pasteWidget();
      } else if(this.imageCopied && this.copiedFile){
        this.pageService.createCopiedContent(this.copiedFile, "image");
        this.copiedFile = null;
      }
    }
  }

  configureFooter(event){
    if (this.isFooterInFocus) {
      this.defocusFooter()
      return
    }
    console.log("configure footer")
    this.isFooterInFocus = true
    this.pageService.footerInFocus.next(true)
  }
  
  defocusFooter(){
    this.isFooterInFocus = false
    this.pageService.footerInFocus.next(false)
    this.metaService.updatePageStructure(this.metaService.page_structure.value)
    this.pageService.restoreContext()
  }

  onActivate(event: any) {
    if (event.sendMessage) {
      event.messageEvent.subscribe((message: string) => {
        // console.log("message received", message);
        this.showFooter = true
        // console.log("show footer is ", this.showFooter)
      });
    }
  }
}
