import { Component, NgZone, OnInit } from '@angular/core';
import { FormService } from '../form.service';
import { filter } from 'rxjs/internal/operators/filter';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { TemplateEngine } from '../../core/common/TemplateEngine';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as confetti from 'canvas-confetti';
import { environment } from 'src/environments/environment';

interface SuccessPageConfig {
  actionType: string;
  richTextContentHtml: string
  externalUrl: string,
  isCelebrate: boolean
}

@Component({
  selector: 'app-success-page-config',
  templateUrl: './success-page-config.component.html',
  styleUrls: ['./success-page-config.component.scss']
})
export class SuccessPageConfigComponent implements OnInit {

  count = 200;
  defaults = {
    origin: { y: 0.7 }
  };

  myConfetti:any;

  formCode: any;
  form: any;
  formPanel: any;
  fields: any[] = [];
  // selectedActionType: any = 'thankyou';  // thankyou || external
  // externalUrl: string = '';
  isValidExternalUrl: boolean;
  richtextWidgetMeta: any;
  // richTextContent: any;
  isReady: boolean = false
  successPageConfig: SuccessPageConfig = {
    actionType: 'thankyou',
    richTextContentHtml: '<h1 class="ql-align-center">🙌</h1><h1 class="ql-align-center"><strong>Thank you for submitting the form.</strong></h1><p class="ql-align-center">Your submission has been received successfully.</p>',
    externalUrl: '',
    isCelebrate: true
  }
  formValues: any = {}  // field => value
  environment = environment
  viewMode: boolean;
  myCanvas: HTMLCanvasElement;

  constructor(
    private formService: FormService,
    public router: Router,
    private spinnerService: SpinnerService,
    public authService: AuthServiceService,
    public connectionService: ConnectionService,
    private _ngZone: NgZone,
    private route: ActivatedRoute,
    private pageService: PageService,
    private _snackbar: MatSnackBar,

  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((event: any) => {
        let url = decodeURI(event?.url)
        console.log("[FORM RESPONSES] constructor(): url", url)
        let pathParts = url?.split('/')
        if (pathParts[1] == 'form' && pathParts[4] == 'success') {
          this.formCode = pathParts[3]
        }
        if(pathParts[2] == 'build'){ this.viewMode = false; }
        else if(pathParts[2] == 'p'){ this.viewMode = true; }
        console.log("[RESPONSES] constructor(): formCode", this.formCode)
      });
   }

  ngOnInit(): void {
    console.log("form code", this.formCode)
    if(this.formService.isPreviewSuccess) {
      this.formCode = this.formService?.formMeta?.value?.code;
      this.viewMode = true;
    }
    this.myCanvas = document.createElement('canvas');
    this.myCanvas.style.cssText = 'width: 150vh;position:absolute;top:25%'
      var myCanvasId = document.getElementById('my-canvas');
      myCanvasId.prepend(this.myCanvas)
      this.myConfetti = confetti.create(this.myCanvas, {
        resize: true,
      });
    this.loadForm()
  }

  async loadForm(){
    console.log("currently in service", this.formService?.formMeta?.value)
    if(this.formCode && this.formService?.formMeta?.value?.code == this.formCode){
      this.form = this.formService.formMeta.value
    }else{
      try{
        this.form = await this.formService.getFormByCode(this.formCode, null, "published")
      }catch(e){
        console.log("error in fetching form", e)
      }
    }
    console.log("form loaded", this.form)
    this.successPageConfig.actionType = this.form?.successAction?.actionType || 'thankyou'

    this.successPageConfig = this.form.successPageConfig || this.successPageConfig;
    if(!this.successPageConfig.hasOwnProperty('isCelebrate')) this.successPageConfig.isCelebrate = true;

    this.createFormFields()
    this.createRichtextMeta()
  }

  createFormFields(){
    let fields = []
    let formPanel = this.form.panels.find(p => p.type == 'formpanel')
    formPanel.formAttributes.forEach(attr => {
      if(attr.enabled){
        fields.push({
          name: attr.name,
          __id: attr.__id,
          dataType: 'string'
        })
      }
    })
    console.log("fields generated", fields)
    this.fields = fields
  }

  createRichtextMeta(){
    let richTextValue = this.successPageConfig?.richTextContentHtml || ""

    richTextValue = this.templateReplace(richTextValue);

    this.richtextWidgetMeta = WidgetManager.getWidget('richtext')
    this.richtextWidgetMeta.setValue(richTextValue)
    if(this.viewMode) this.richtextWidgetMeta.config.viewOnly.value = true
    console.log("widget meta created", this.richtextWidgetMeta)
    this.isReady = true;
    if(this.viewMode && this.successPageConfig?.isCelebrate){

      this.popStars();
      setTimeout(() => {
        this.popStars();
        if (this.myCanvas && this.myCanvas.parentNode) {
          this.myCanvas.parentNode.removeChild(this.myCanvas);
          console.log("Canvas removed");
        }
      }, 2000);
    }
  }

  onCeleberationChange(e){
    if(e.checked){
      this.popStars();
    }
  }

  templateReplace(richTextValue){
    // get form values
    let pageModel = this.pageService.pageModel
    console.log("pageModel", pageModel)

    // find form values by form field names
    let formPanel = this.form.panels.find(p => p.type == 'formpanel')
    let panelId = formPanel.id
    this.fields.forEach(field => { this.formValues[field.__id] = "" })
    this.fields.forEach(field => {
      // get the widget id associated with the form attribute
      let widgetId;
      formPanel.layoutMap?.list?.forEach(colId => {
        formPanel.layoutMap[colId].list.forEach(rowId => {
          formPanel.layoutMap[colId][rowId].elements.forEach(wid => {
            if(wid.id.split('-')[1] == field.__id){
              widgetId = wid.id
            }
          })
        })
      })
      this.formValues[field.__id] = pageModel?.[panelId]?.[widgetId]?.value
    })
    let te = new TemplateEngine
    richTextValue = te.fill(richTextValue, this.formValues)
    console.log("templates replaced", richTextValue)
    return richTextValue
  }

  successActionSelected(event){
    console.log("success action selected", event.value)
    this.successPageConfig.actionType = event.value
    // this.form.successAction.actionType = event.value
  }

  externalUrlInput(value){
    console.log("external url typed", value)
    // this.checkValidity(value)
  }

  checkValidity(url){
    console.log("check validity", url)
    try {
      new URL(url);
      this.isValidExternalUrl = true
      console.log("url valid")
      return true;
    } catch (err) {
      this.isValidExternalUrl = false
      console.log("invalid!!!")
      return false;
    }
  }

  thankYouContentReceived(data){
    this.successPageConfig.richTextContentHtml = data.value
    console.log("thank you rich text input", this.successPageConfig.richTextContentHtml)
  }

  goBack(){
    console.log("will navigate to build/", this.formCode)
    this.router.navigate(["form", "build", this.formCode], { fragment: "edit" })
  }

  async saveConfig(){
    console.log("current form meta", this.form)

    this.successPageConfig['actionType'] = this.successPageConfig.actionType

    let successAction = this.createNavAction()
    if (this.successPageConfig.actionType == 'thankyou') {
      successAction.actionMap['pageType'] = 'form_success'
      // this.successPageConfig['richtextContentHtml'] = this.richTextContent
    } else if (this.successPageConfig.actionType == 'external') {
      successAction.actionMap['pageType'] = 'link'
      // this.successPageConfig['externalUrl'] = this.externalUrl
      successAction.actionMap['link'] = this.successPageConfig.externalUrl
    }

    this.form['successPageConfig'] = this.successPageConfig
    console.log("success action", successAction)

    // find the button meta
    let submitPanel = this.form.panels[this.form.panels.length - 1]
    let colId = submitPanel.layoutMap.list[0]
    let rowId = submitPanel.layoutMap[colId].list[0]
    let button = submitPanel.layoutMap[colId][rowId].elements[0]
    console.log("submit button", button)
    let navActionIndex = button.actionConfig.actions.findIndex(action => action.formSuccessAction == true)
    console.log("navAction found", navActionIndex)
    if(navActionIndex > -1) button.actionConfig.actions[navActionIndex] = successAction
    else button.actionConfig.actions.push(successAction)
    console.log("action updated", button.actionConfig.actions)

    submitPanel.layoutMap[colId][rowId].elements[0] = button
    console.log("panel", JSON.parse(JSON.stringify(submitPanel)))
    this.form.panels[this.form.panels.length - 1] = submitPanel
    console.log("form meta", JSON.parse(JSON.stringify(this.form.panels)))

    let updateResp = await this.formService.updateForm(this.form)
    console.log("form updated", updateResp)

    this._snackbar.open("Form success action is configured and saved ✔️", '', { duration: 3000 });
  }

  insertCursor(event){
    console.log("event", event)
    let templateText = "${" + event.__id + "}"
    this.successPageConfig.richTextContentHtml += templateText
    this.richtextWidgetMeta.config.contentHtml.value += templateText
  }

  createNavAction(){
    return {
      "event": "click",
      "action": "navigation",
      "formSuccessAction": true,
      "actionMap": {
        "mapping": [],
        "parameters": []
      }
    }
  }

  fire(particleRatio, opts) {
    this.myConfetti(Object.assign({}, this.defaults, opts, {
      particleCount: Math.floor(this.count * particleRatio)
    }));
  }

  popStars(){
    this.fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    this.fire(0.2, {
      spread: 30,
    });
    this.fire(0.35, {
      spread: 40,
      decay: 0.91,
      scalar: 0.8
    });
    this.fire(0.1, {
      spread: 40,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    this.fire(0.1, {
      spread: 70,
      startVelocity: 45,
    });
  }
}
