import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith } from 'rxjs/operators';
import { BoxService } from '../../services/box-service.service';
import { PageService } from '../../services/page-service.service';

@Component({
  selector: 'selection-widgets-options-config',
  templateUrl: './config-for-selection-widgets.component.html',
  styleUrls: ['./config-for-selection-widgets.component.scss']
})
export class ConfigForSelectionWidgetsComponent implements OnInit {

  @Input() widgetMeta: any
  @Output() newMeta: any = new EventEmitter<any>()

  supportedWidgetTypes: string[] = ['select', 'checkbox', 'autocomplete', 'chips', 'radio']
  editModeIndex: number = -1
  hoveredIndex: number = -1
  // selectedDefaultIndex: number = -1
  // selectedDefaultValue: any
  defaultCount: number = 0
  staticOptions: any[]
  dynamicOptions: any
  dynamicOptionfilter: any

  filteredNameAttributes: any;
  filteredValueAttributes: any;
  nameAttributeControl = new UntypedFormControl();
  valueAttributeControl = new UntypedFormControl();

  isAppSelected: boolean = false
  isConnectionSelected: boolean = false
  isBoxObjectSelected: boolean = false

  selectedBoxName: string;
  selectedBoxId: string;
  selectedConnectionId: string;
  selectedBoxObjectId: string;

  canGetBoxObjects: boolean = false

  boxFunctionSpinner: boolean = false
  gettingObjFunSpinner: boolean = false
  attributeSpinner: boolean = false

  objectFuntions: any[] = []
  boxFunctions: any[] = []
  boxConfigToken: string

  isBoxObjectConfigError: boolean = false;
  boxObjectConfigError: any = {};
  disableBoxObjectInput: boolean = false

  getFn: any
  attributeOptions: any[] = []
  getFnOptions: any[] = []
  isOptionsToCollect: boolean = false
  isGetOptionsToCollect: boolean = false
  isAttributesError: boolean = false
  isGetFnOptionsError: boolean = false
  attributeError: any
  terminationError: any
  terminationErrorMessage: any

  nameAttribute: any;
  namePath: any;
  valueAttribute: any
  valuePath: any

  getAttrFn: any;
  attributes: any = []
  isAttributesReady: boolean = false
  collectAttrOptions: boolean = false
  collectGetOptions: boolean = false
  availableInputParams: any = {
    options: {}
  }

  userDataAttributes: any = [
    {
      name: "_id",
      __id: "_id",
      dataType: "string"
    },
    {
      name: "First Name",
      __id: "first_name",
      dataType: "string"
    },

    {
      name: "Last Name",
      __id: "last_name",
      dataType: "string"
    },

    {
      name: "Email",
      __id: "email",
      dataType: "string"
    }
  ];

  advancedModeOpenName: boolean = false
  advancedModeOpenValue: boolean = false

  constructor(
    private boxService: BoxService,
    private pageService: PageService,
    private _snack: MatSnackBar
  ) { }

  ngOnInit(): void {

    console.log("config for selection widgets: onInit(): widgetMeta", this.widgetMeta)

    this.filteredNameAttributes = this.nameAttributeControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._attributeFilter(value))
    );

    this.filteredValueAttributes = this.valueAttributeControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._attributeFilter(value))
    );

    this.staticOptions = this.widgetMeta?.config?.availableOptions?.staticOptions
    this.defaultCount = this.staticOptions.filter(opt => opt.default).length
    // this.selectedDefaultIndex = this.widgetMeta?.config?.availableOptions?.defaultValueIndex
    // this.selectedDefaultValue = this.widgetMeta?.config?.availableOptions?.staticOptions[this.selectedDefaultIndex] ? this.staticOptions[this.selectedDefaultIndex].value : undefined
    this.dynamicOptions =  JSON.parse(JSON.stringify(this.widgetMeta.config.availableOptions.dynamicOptions))
    // this.dynamicOptionfilter = this.dynamicOptions.filter || {}

    if(this.dynamicOptions && this.dynamicOptions.enabled && this.dynamicOptions.boxId && (this.dynamicOptions.connectionId || this.dynamicOptions.boxConfigToken) && this.dynamicOptions.boxObjectId){
      console.log("this.dynamicOptions", this.dynamicOptions)

      this.isAppSelected = true
      this.isConnectionSelected = true
      this.isBoxObjectSelected = true

      this.selectedBoxId = this.dynamicOptions.boxId
      this.selectedConnectionId = this.dynamicOptions.connectionId
      this.selectedBoxObjectId = this.dynamicOptions.boxObjectId;
      this.attributeOptions = this.dynamicOptions.attributeOptions
      console.log("was", this.dynamicOptions.attributeOptions)
      console.log("assigned", this.attributeOptions)
      this.getFnOptions = this.dynamicOptions.getFnOptions
      console.log("was", this.dynamicOptions.getFnOptions)
      console.log("assigned", this.getFnOptions)
      this.boxObjectSelected(this.selectedBoxObjectId)
    } else if(this.dynamicOptions?.userData){
      this.userDataSelected();
    }
    this.valueAttribute = this.dynamicOptions.valueAttribute
    this.valueAttributeControl.patchValue(this.valueAttribute)
    this.nameAttribute = this.dynamicOptions.nameAttribute
    this.nameAttributeControl.patchValue(this.nameAttribute)
    this.namePath = this.dynamicOptions.namePath
    this.valuePath = this.dynamicOptions.valuePath


    console.log("initialized options data", this.staticOptions)
    console.log("dynamic options", this.dynamicOptions)
  }

  defaultChanged(event, i){
    console.log("default changed", event, i)

    // single default
    if(this.widgetMeta.type == 'select' || this.widgetMeta.type == 'radio' || this.widgetMeta.type == 'autocomplete'){
      this.clearDefaultStatic()
    }else{    // checkbox and chips with multi default
      // pass
    }
    this.staticOptions[i].default = event.checked

    console.log("options updated", this.staticOptions)

    this.defaultCount = this.staticOptions.filter(opt => opt.default).length
    this.saveChanges()
  }

  clearDefaultStatic(){
    // this.selectedDefaultIndex = -1
    // this.selectedDefaultValue = undefined
    this.staticOptions.map(opt => opt.default = false)
    this.defaultCount = this.staticOptions.filter(opt => opt.default).length
    // this.saveChanges()
    // console.log("after save changes")
  }

  editOption(i: number){
    console.log("edit static clicked for", i, "option", this.staticOptions[i])
    this.editModeIndex = i
  }

  addNewStaticOption(){
    let option = {
      name: this.getUniqueValue(),
      value: this.getUniqueValue(),
      default: false
    }
    this.staticOptions.push(option)
    console.log(this.staticOptions.length)
    this.editOption(this.staticOptions.length - 1)
    // this.cdr.detectChanges()
    this.saveChanges()
  }

  optionNameChanged(event, i){
    console.log("new name", event.srcElement.value, "for index", i)
    this.staticOptions[i].name = event.srcElement.value
    this.saveChanges()
  }

  getUniqueValue(){
    let valuePoint = this.staticOptions.length
    let value = 'New Option ' + valuePoint
    while(this.staticOptions.findIndex(opt => opt.value == value) > -1){
      value = "New Option " + ++valuePoint
    }
    return value
  }

  optionValueChanged(event, i){
    console.log("new value", event.srcElement.value, "for index", i)
    this.staticOptions[i].value = event.srcElement.value
    this.saveChanges()
  }

  saveEditedOption(){
    this.editModeIndex = -1
  }

  deleteOption(i){
    console.log("delete option at index", i)
    this.staticOptions.splice(i, 1)
    if(this.editModeIndex == i) this.editModeIndex = -1
    this.saveChanges()
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.staticOptions, event.previousIndex, event.currentIndex);
    this.saveChanges()
  }

  dynamicOptionsToggle(event){
    console.log("dynamic toggled", event.checked)
    if(!this.dynamicOptions) this.dynamicOptions = {};
    this.dynamicOptions.enabled = event.checked
    this.saveChanges()
  }

  /**
   *
   * @param boxObjectId
   */
  async boxObjectSelected(boxObjectId: any) {
    console.log("box object selected", boxObjectId)
    if(this.selectedBoxObjectId && this.selectedBoxObjectId != boxObjectId){
      this.resetAttributeConfigs()
    }

    this.selectedBoxObjectId = boxObjectId
    this.isBoxObjectSelected = true


    this.gettingObjFunSpinner = true;
    let boxId = this.selectedBoxId == 'starch' ? this.dynamicOptions.baseMap?.box_id : this.selectedBoxId;
    let conType = this.selectedBoxId == 'starch' ? "token": '';
    let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken : this.selectedConnectionId;
    this.objectFuntions = await this.boxService.getBoxObjectFuntions(boxId, this.selectedBoxObjectId, conKey, conType)
    this.gettingObjFunSpinner = false
    console.log("box object functions received", this.objectFuntions)

    let getFn: any = this.objectFuntions?.find(fn => fn.__id == 'get')
    let getAttributesFunction = this.boxFunctions?.find(fn => fn.__id == 'getattributes')

    if(!getAttributesFunction){
      console.log("getAttributes function not found")
      this.terminationError = true
      this.terminationErrorMessage = `'getAttributes' function not supported on ${this.selectedBoxObjectId} of ${this.selectedBoxId}.`
      return
    } else if(!getFn) {
      console.log("get function not found")
      this.terminationError = true
      this.terminationErrorMessage = `'get' function not supported on ${this.selectedBoxObjectId} of ${this.selectedBoxId}.`
      return
    }

    this.constructAttrOptions()
  }

  resetObjectConfigs(){
    this.selectedBoxObjectId = ""
    this.isBoxObjectSelected = false
    this.resetAttributeConfigs()
  }

  resetAttributeConfigs(){
    this.collectAttrOptions = false
    this.attributeOptions = []
    this.collectGetOptions = false
    this.getFnOptions = []
    this.isAttributesReady = false
    this.attributes = []
    this.availableInputParams.options = {}
  }

  // calculates if input parameters needed for fetching attributes
  async constructAttrOptions(){
    console.log("[CONSTRUCT ATTR OPTIONS]")
    this.getAttrFn = this.boxFunctions.find(fn => fn.__id == 'getattributes')
    if(!this.getAttrFn) return
    this.attributeOptions = this.attributeOptions?.length ? this.attributeOptions : this.pageService.checkOptionsToCollect(this.getAttrFn)

    console.log("attributeOptions assigned", this.attributeOptions)

    // keep availableInputParams in sync with attribute Options
    this.attributeOptions.forEach(op => this.availableInputParams.options[op.__id || op.name] = op.value)

    console.log("availableInputParams initialized", this.availableInputParams)

    if(this.attributeOptions?.length){
      this.collectAttrOptions = true

      // when all required options already have value
      if(!this.attributeOptions.find(op => op.required && !op.value)){
        this.constructGetOptions()
      }
    }else{
      this.constructGetOptions()
    }

  }

  // calculates if input parameters needed for get fn
  constructGetOptions(){
    console.log("[CONSTRUCT GET OPTIONS]")
    this.getFn = this.objectFuntions.find(fn => fn.__id == 'get')
    if(!this.getFn) return
    console.log("existing", JSON.parse(JSON.stringify(this.getFnOptions)))
    this.getFnOptions = this.getFnOptions.length ? this.getFnOptions : this.pageService.checkOptionsToCollect(this.getFn)
    if(!this.getFnOptions?.length) {
      this.getAttributes()
    } else {
      // if some property already there in availableInputParams, consider as existing value
      this.getFnOptions?.forEach(el => {
        el.value = this.availableInputParams.options[el.__id] || el.defaultValue || ""
      })

      // if a new property is there in getFnOptions but not in availableParams, add it to availableParams
      this.getFnOptions.forEach(op => {
        if(!this.availableInputParams.options.hasOwnProperty[op.__id]){
          this.availableInputParams.options[op.__id] = op.value
        }
      })

      this.collectGetOptions = true

      // when all required options already have value
      if(!this.getFnOptions.find(op => op.required && !op.value)){
        this.getAttributes()
      }
    }
  }

  async attributeOptionsCollected(){
    console.log("attribute inputs collected")
    this.constructGetOptions()
    // await this.getAttributes();
  }

  /**
   * bound with event emitter to catch input parameters for getAttribute functions
   * @param attrInputParams
   */
  attrOptionInputsRecevied(attrInputParams){
    console.log("attribute input params received", attrInputParams)
    if(!attrInputParams.options) return
    Object.keys(attrInputParams.options).forEach(optionId => {
      this.attributeOptions.forEach(attrOp => {
        if(attrOp.__id == optionId){
          attrOp.value = attrInputParams.options[optionId]
        }
      })
      // update availableInputParams for further use
      this.availableInputParams['options'][optionId] = attrInputParams.options[optionId]
    })
    console.log("availableInputParams updated", this.availableInputParams)
    console.log("attribute options", this.attributeOptions)
    // this.
  }

   /**
   * bound with event emitter to catch input parameters for other getFn functions
   * @param getFnInputParams
   */
   getOptionInputsRecevied(getFnInputParams){
    console.log("getFn input params received", getFnInputParams)
    if(!getFnInputParams?.options) return
    Object.keys(getFnInputParams.options).forEach(optionId => {
      this.getFnOptions.forEach(getOption => {
        if(getOption.__id == optionId){
          getOption.value = getFnInputParams.options[optionId]
        }
      })
    })
    console.log("get options", this.getFnOptions)
  }

  async getBoxFunctions(box, token) {
    this.boxFunctionSpinner = true
    let res = await this.boxService.getBoxFunctions(box, token)
    console.log("box functions received", res)
    if(res.find(fn => fn.__id == "getobjects")){
      this.canGetBoxObjects = true
    }
    this.boxFunctionSpinner = false
    this.boxFunctions = res
    return this.boxFunctions
  }


  async getAttributes(){
    let boxId = this.selectedBoxId == 'starch' ? this.dynamicOptions.baseMap?.box_id : this.selectedBoxId;
    let conType = this.selectedBoxId == 'starch' ? "token": '';
    let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken || this.dynamicOptions.boxConfigToken : this.selectedConnectionId;

    let payloadOptions:any = {}
    if(this.selectedBoxId == 'starch'){
      payloadOptions = {
        relationObject: "starch_relationship"
      }
    }

    console.log("this.dynamicOptions", this.dynamicOptions)
    let res: any = await this.boxService.getAttributes(conKey, boxId, this.selectedBoxObjectId, this.attributeOptions, null, conType)
    this.attributes = res.result
    console.log("attributes fetched", this.attributes)
    this.isAttributesReady = true
  }

  displayFn(attr) {
    return attr && attr.__id ? attr.__id : ''
  }

  private _attributeFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.attributes.filter(option => option.__id.toLowerCase().includes(filterValue));
  }

  boxObjectSelectionError(event){
    console.log("box object selection error", event)
    this.isBoxObjectConfigError = true
    this.boxObjectConfigError = event
  }

  nameAttributeSelected(event: any){
    console.log("name attribute selected", event.option.value)
    this.nameAttribute = event.option.value
    this.saveChanges()
  }

  valueAttributeSelected(event: any){
    console.log("value attribute selected", event.option.value)
    this.valueAttribute = event.option.value
    this.saveChanges()
  }

  userDataSelected(){
    if(!this.dynamicOptions.userData) {
      this.nameAttributeControl.patchValue('');
      this.valueAttributeControl.patchValue('');
      this.attributes = [];
      this.isAttributesReady = false;
      return;
    } else {
      this.attributes = this.userDataAttributes;
      this.isAttributesReady = true;
    }

  }

  newDynamicDataSourceRceived(data){
    console.log("DATA SOURCE", data);
    this.dynamicOptions = data;
    this.selectedConnectionId = data.connectionId
    this.selectedBoxName = data.boxName
    this.selectedBoxId = data.boxId
    this.selectedBoxObjectId = data.boxObjectId
    this.getFnOptions = data.getFnOptions
    this.attributeOptions = data.attributeOptions
    this.boxConfigToken = this.dynamicOptions.boxConfigToken
    this.getAttributes()
  }

  filterSelected(data){
    console.log("filter received", data);
    this.dynamicOptions.filter = data;
    this.saveChanges()
  }

  saveChanges(){
    console.log("this.dynamicOptions", this.dynamicOptions)
    this.widgetMeta.config.availableOptions.staticOptions = this.staticOptions
    if(!this.dynamicOptions.enabled) {
      this.widgetMeta.config.availableOptions.dynamicOptions.enabled = false
      this.newMeta.emit(this.widgetMeta)
      return
    }
    console.log("this.valueAttribute this.nameAttribute", this.valueAttribute, this.nameAttribute);
    console.log("this.selectedConnectionId this.boxConfigToken", this.selectedConnectionId, this.boxConfigToken);

    if(!this.dynamicOptions?.userData && this.dynamicOptions.enabled && (!(this.selectedConnectionId || this.boxConfigToken) || !this.selectedBoxId || !this.selectedBoxObjectId || !this.valueAttribute || !this.nameAttribute)) {
      console.error("one or more required fields not selected!")
      this._snack.open("one or more required fields not selected!", '', {duration: 3000})
      return
    }
    // this.widgetMeta.config.availableOptions.defaultValueIndex = this.selectedDefaultIndex

    this.dynamicOptions.connectionId = this.selectedConnectionId
    this.dynamicOptions.boxName = this.selectedBoxName
    this.dynamicOptions.boxId = this.selectedBoxId
    this.dynamicOptions.boxObjectId = this.selectedBoxObjectId
    this.dynamicOptions['nameAttribute'] = this.nameAttribute
    this.dynamicOptions['valueAttribute'] = this.valueAttribute
    this.dynamicOptions['namePath'] = this.namePath
    this.dynamicOptions['valuePath'] = this.valuePath
    this.dynamicOptions['getFnOptions'] = this.getFnOptions
    this.dynamicOptions['attributeOptions'] = this.attributeOptions

    this.widgetMeta.config.availableOptions.dynamicOptions = this.dynamicOptions;

    console.log("save changes", this.widgetMeta)
    this.newMeta.emit(this.widgetMeta)
  }

}
