<div>
  <app-app-selection
    [existingBox]="step.box_id"
    [onlyDisplay]="!editMode"
    [config]="{isSupportStarch: true}"
    (selectedBox)="appSelected($event)"
  ></app-app-selection>

  <app-connection-selection
  *ngIf="step?.box_id && step.box_id !== 'starch'"
    [onlyDisplay]="!editMode"
    [existingCon]="step.connection"
    [config]="connectionSelectionConfig"
    (selectedConnection)="connectionSelected($event)"
  ></app-connection-selection>

  <base-selection *ngIf="step.box_id === 'starch'"
    [existingBase]="step?.baseMap?.baseId"
    [onlyDisplay]="!editMode"
    (selectedBase)="baseSelected($event)">
  </base-selection>

  <!-- *ngIf="step.box_id !== 'starch' || (step.box_id === 'starch' && step.boxConfigToken)" -->
  <app-action-selection
    *ngIf="step.connection || (step.baseMap && step.baseMap.base_id)"
    [config]="{
      box_id: step.box_id,
      connection: step.connection,
      actionMap: step.actionMap ,
      base_box_id: step.baseMap?.box_id,
      boxConfigToken: step.boxConfigToken
    }"
    [onlyDisplay]="!editMode"
    [existingActionId]="step.action"
    (actionFields)="stepActionSelected($event)"
    (selectedAction)="step.action = $event.__id;stepOptions.action = $event;selectedAction($event)"
    (attributeOptionsInput)="attributeOptionsInput($event, step)"
  ></app-action-selection>

  <!-- {{stepOptions|json}} -->
  <div *ngIf="step.action && stepOptions.action.__id">
    <!-- <div *ngIf="stepOptions.action.crudType == 'R' || stepOptions.action.functionName == 'get'"> -->
      <!-- [isSupportFilter]="true" -->
      <!-- [isSupportGroup]="true" -->
      <!-- [isSupportCountLimit]="true" -->

      <div *ngIf="stepOptions?.action?.crudType === 'R'">
        <p><mat-slide-toggle [checked]="step?.step_config?.incremental_query" (change)="setIncrementalQuery($event)">Incremental query</mat-slide-toggle></p>

        <div *ngIf="incrementalImportDateTimeFields?.length && step.step_config?.incremental_query">
          <mat-form-field style="width: 100%;">
            <mat-label>Select field</mat-label>
            <mat-select
            [(value)]="step.step_config.incremental_map.incremental_query_field"
            (selectionChange)="incrementalInputReceived($event)"
            (click)="$event.preventDefault()">
              @for (obj of incrementalImportDateTimeFields; track obj) {
                <mat-option [value]="obj.__id">{{obj.name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <shared-parameter-inputs *ngIf="showSharedParameterInputs"
        [isOnlyOptions]="stepOptions.action.crudType === 'R' ? false : true"
        [isSupportQuery]="stepOptions.action.crudType === 'R' ? true : false"
        [queryInputs]="{
          attributeInputOptions: step.actionMap?.attributeInputOptions ? step.actionMap.attributeInputOptions : undefined,
          subComponentsToShow: {filter: true, limit: true, group: true },
          filterConfig: step.inputParams ? step.inputParams.filterConfig : undefined,
          groupConfig: step.inputParams ? step.inputParams.groupConfig : undefined,
          limit: step?.inputParams?.__limit,
          retainSubComponentOutput: true
        }"
        [action]="stepOptions.action"
        [optionalInputs]="optionalInputs"
        [existingInputs]="step.inputParams"
        [connection]="step.box_id === 'starch' ? step.boxConfigToken : stepOptions.connection"
        [starchBase]="step.baseMap"
        [fieldListingMap]="{list: ['sourceFields', 'systemFields'], sourceFields: sourceFields, systemFields: systemFields}"
        (inputParameter)="inputRecevied($event)"
      ></shared-parameter-inputs>
    <!-- </div> -->
    <!-- <div>{{ "stepOptions.actionFields  " + (stepOptions.actionFields | json) }}</div>
    <div>{{ "stepOptions.action.crudType  " + stepOptions.action.crudType }}</div> -->
    <div
      *ngIf="stepOptions.actionFields.length > 0 && ['C', 'U', 'E'].includes(stepOptions.action.crudType)">
      <div fxLayoutAlign="start center">Action parameters</div>
      <app-mapping-setup
        (mappingChange)="onmappingChange($event)"
        [mappingArr]="step.mapping"
        [sourceFields]="sourceFields"
        [appFields]="stepOptions.actionFields"
        [appInfo]="mappingAppInfo(stepOptions.box)"
        [displayOption]="{'showHelpLinks': true}"
      >
      </app-mapping-setup>
    </div>
  </div>
</div>
