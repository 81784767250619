import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { PanelComponent } from '../../page/panel/panel.component';
import { BoxService } from '../../services/box-service.service';
import { MetaService } from '../../services/meta-service';
import { PageService } from '../../services/page-service.service';
import { FormPanelDialogComponent } from './form-panel-dialog/form-panel-dialog.component';
import { WidgetAction } from '../../models/Action/WidgetAction'
import { WidgetManager } from '../../models/WidgetManager';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ActivatedRoute } from '@angular/router';
import { AutomationService } from '../../services/automation.service';
import { ContextDataService } from 'src/app/shared/services/context-data.service';
import { UrlParamsService } from 'src/app/shared/services/URLParamService';

interface Page {
  number: number,
  size: number,
  total: number,
  nextPageToken?: string,
  previousPageToken?: string
}

@Component({
  selector: 'app-form-panel',
  templateUrl: './form-panel.component.html',
  styleUrls: ['./form-panel.component.scss']
})
export class FormPanelComponent extends PanelComponent implements OnInit, OnDestroy, OnChanges {

  @Input() panelMeta;
  @Input() builderMode;
  @Input() inputData;

  @Output() manageSpinner = new EventEmitter<any>();

  page: Page;
  pageMeta: any;

  runSpinner: boolean = false;
  defaultPageSize: number = 1;
  defaultPageNumber: number = 1;
  currentPageNumber: number = 1;

  dataLoadError: any
  rawBoxData: any;
  dataLength: number;
  widgets: any[] = []
  submitButtonMeta: any
  currentPageCode: string;
  formReady: boolean = false;
  navigationDataSubscription: any
  routeDataSubscription: any
  margin: any;
  navigationDataReceived: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  panelLoaded: boolean = false;

  private ngUnsubscribe : Subject<boolean> = new Subject();

  constructor(
    private dialog: MatDialog,
    private boxService: BoxService,
    private metaService: MetaService,
    private pageService: PageService,
    private widgetAction: WidgetAction,
    public sps: SpinnerService,
    public route: ActivatedRoute,
    private automationService: AutomationService,
    public contextDataService: ContextDataService,
    private urlService: UrlParamsService,
  ) {
    super()
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if(changes.panelMeta?.currentValue){
      console.log("[FORM PANEL] panelMeta", changes.panelMeta.currentValue)
      // if(changes.panelMeta.currentValue.mode && changes.panelMeta.currentValue.mode == 'create') return;
      let staticFilters: any[] = changes.panelMeta.currentValue.filter?.filterItems?.filter(f => f.filterType == 'static_filter')
      console.log("static filter", staticFilters)
      if(this.panelMeta.fromBloom == false) this.loadBoxData()
      else if(staticFilters?.length) await this.loadBoxData(staticFilters)
    }
  }

  ngOnInit(): void {
    console.log("INPUT ROW DATA",this.inputData);
    console.log("PANEL META",this.panelMeta);
    console.log("BUILDER MODE",this.builderMode);
    // this.resetWidgets()
    this.clearWidgetValues();


    let that = this;

    this.pageService.actionMode = 'create'

    // make the mode of submit button meta as create, will be set as edit if data loads
    let submitButtonMeta = this.automationService.getSubmitButtonMeta(this.panelMeta)//this.panelMeta.widgets[this.panelMeta.widgets.length - 1]
    submitButtonMeta.actionConfig.actions[0].actionMap.actionMode = 'create'
    this.automationService.setSubmitButtonMeta(this.panelMeta, submitButtonMeta)
    // this.panelMeta.widgets[this.panelMeta.widgets.length - 1] = submitButtonMeta

    this.pageService.currentPageCode.subscribe(code => {
      this.currentPageCode = code
    })

    if(this.panelMeta?.margin){
      this.margin = this.panelMeta.margin
    }

    //SUBSCRIBE TO PAGE META
    this.metaService.pageMeta.subscribe(meta => {
      // console.log("form panel onInit: page meta:", meta)
      if(this.currentPageCode == meta.code){
        this.pageMeta = meta;
      }
    })

    // this.navigationDataSubscription = this.pageService.$navigationData.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (data: any) => {
    //   if(this.panelMeta.fromBloom == false) return;
    //   // check if incoming connectionId, boxId and boxObject id matches with that of form's
    //   console.log("[FORM PANEL] navigation data received:", data)
    //   // if(!data || Object.keys(data).length == 0) return;
    //   if(!data) {
    //     if(this.pageService.actionMode != "update" && !this.metaService.formPanelLoaded) this.generatePrefillActionConfig({});
    //     return;
    //   }
    //   if(Object.keys(data).length > 0) this.navigationDataReceived = true;
    //   console.log("own data binding:", that.panelMeta)
    //   if(data?.["destination-page-code"] !== that.currentPageCode && !data?.isActionNav) {
    //     this.generatePrefillActionConfig({});
    //     return
    //   }
    //   if(that.panelLoaded) return;

    //   let navFilters = data?.filters ? data.filters : data.dataBindSetup.filters;
    //   if(!navFilters || !navFilters.length) return;
    //   let currentFilters = navFilters
    //   if(this.panelMeta?.filter?.filterEnabled){
    //     currentFilters = this.navigationFilterReplacer(navFilters);
    //     console.log("is going to execute", currentFilters, navFilters)
    //   }
    //   await that.loadBoxData(currentFilters);
    // })

    this.routeDataSubscription = this.route.queryParams.subscribe(async params => {
      console.log("details panel meta received in query params: ", params);
      if(!params || Object.keys(params).length == 0){
        params = this.urlService.parseUrlToObj();
      }
      let navFilters = [];
      for (const key in params) {
        if(key != "v") navFilters.push({
          attributeId: decodeURIComponent(key),
          dataType: "string",
          value: decodeURIComponent(params[key])
        })
      }
      if(!navFilters.length) return
      let currentFilters = navFilters
      if(this.panelMeta?.filter?.filterEnabled){
        currentFilters = this.navigationFilterReplacer(navFilters);
        console.log("is going to execute", currentFilters, navFilters)
        await that.loadBoxData(currentFilters);
      }
    })
  }


  clearWidgetValues(){
    console.log("clear widget values hit", JSON.parse(JSON.stringify(this.panelMeta)))
    this.panelMeta.layoutMap.list.forEach(colId => {
      this.panelMeta.layoutMap[colId]['list'].forEach(rowId => {
        this.panelMeta.layoutMap[colId][rowId].elements.forEach(wid => {
          if(String(wid.id).includes('submit') || wid.type === 'button') return
          let temp = WidgetManager.getWidget(wid.type, wid.id, wid.name)
          Object.keys(wid).forEach(prop => temp[prop] = wid[prop])
          temp.setValue('')
          wid = temp
        })
      })
    })
    console.log("widgets cleared", this.panelMeta.layoutMap)
    // let wids = this.pageService.getWidgetsFromPanel(this.panelMeta)
    // console.log("widgets to clear", wids)
    // wids.forEach((w, i) => {
    //   if(w.id.includes('submit') && w.type === 'button') return
    //   // console.log("creating clone of ", w)
    //   let temp = WidgetManager.getWidget(w.type, w.id, w.name)
    //   Object.keys(w).forEach(prop => temp[prop] = w[prop])

    //   temp.setValue('')
    //   wids[i] = JSON.parse(JSON.stringify(temp))
    // });
    // this.panelMeta['widgets'] = wids
    // super.migrateExistingPanelMeta()
  }

  navigationFilterReplacer(navFilters: any){
    let filters: any[] = JSON.parse(JSON.stringify(this.panelMeta?.filter?.filterItems))
    console.log(filters)
    for (let i = 0; i < filters.length; i++) {
      if(filters[i].filterType !== 'navigation_filter') continue
      let navFilterObj = navFilters.find(navFilter => (navFilter.parameter || navFilter.attributeId) == filters[i].value)
      console.log(navFilterObj)
      if(navFilterObj?.value){
        filters[i].value = navFilterObj.value;
        filters[i].dataType = navFilterObj.dataType //TODO change
      } else continue
    }
    return filters;
  }


  ngOnDestroy(): void {
    // this.pageService.navigationData.next(null);
    // this.navigationDataSubscription.unsubscribe();
  }

  openSettings(){
    let dialogRef = this.dialog.open(FormPanelDialogComponent, {
      minHeight: '50vh',
      minWidth: '80vw',
      maxHeight: '90vh',
      data: {
        firstHit: false,
        panelMeta: this.panelMeta,
        pageMeta: this.pageMeta
      },
    })

    dialogRef.afterClosed().subscribe(data=>{
      if(!data){
        console.log("form panel configuration dialog closed unexpectedly")
        return
      }
      console.log("form panel config dialog resolved", data)
      this.runSpinner = true
      this.panelMeta = data

      this.newPanelMeta.emit(this.panelMeta)

      // // scan through pageMeta.panels and find panel matching this.panelMeta.id
      // let i = this.pageMeta?.panels?.findIndex(panel => panel.id == this.panelMeta.id)
      // if(i > -1){
      //   this.pageMeta.panels[i] = JSON.parse(JSON.stringify(this.panelMeta))
      //   console.log("new pageMeta", this.pageMeta)
      //   this.metaService.pageMeta.next(this.pageMeta)
      // }

      // this.metaService.userMadeChanges.next(true);
    })
  }


  async loadBoxData(filters: any[] = []){
    this.runSpinner = true;
    this.sps.show();
    this.manageSpinner.emit({
      panelId: this.panelMeta.id,
      isSpinning: true
    })

    let formAttributeIds = []
    this.panelMeta.formAttributes.forEach(attribute => {
      formAttributeIds.push(attribute.__id)
    });
    console.log("form attribute Ids", formAttributeIds)

    let dataBindConfig = {
      boxId: this.panelMeta.boxId,
      connectionId: this.panelMeta.connectionId,
      boxObject: this.panelMeta.boxObjectId,
      pageNumber: this.defaultPageNumber,
      baseMap: this.panelMeta.baseMap || {},
      boxConfigToken: this.panelMeta.boxConfigToken,
      pageSize: this.panelMeta.defaultListSize || this.defaultPageSize,
      options: this.panelMeta.getFnOptions,
      attributes: formAttributeIds,
      filters: filters
    }

    console.log("dataBindConfig", dataBindConfig)

    //fetch data
    let mode: string;
    if(!this.builderMode){
      mode = 'user_api_key'
    }
    let res: any
    try{
      if(!this.inputData){
        res = await this.boxService.getAny(dataBindConfig, mode)
        this.rawBoxData = res.data[0];
      } else {
        this.rawBoxData = this.inputData
      }
      console.log("RAW BOX DATA",this.rawBoxData);
      this.contextDataService.formPanelLoadedData = this.rawBoxData;

      console.log("[FORM PANEL] boxService getAny result:", res);
      this.panelLoaded = true;

      console.log("panel loaded set", this.panelLoaded)
      this.page = res?.page
      this.dataLength = this.page?.total || 1000
      // set save action mode
      let submitButtonMeta = this.automationService.getSubmitButtonMeta(this.panelMeta)//this.panelMeta.widgets[this.panelMeta.widgets.length - 1]
      submitButtonMeta.actionConfig.actions[0].actionMap.actionMode = 'update'
      this.pageService.actionMode = 'update';
      this.automationService.setSubmitButtonMeta(this.panelMeta, submitButtonMeta)
      console.log("RAW BOX DATA",this.rawBoxData);

      this.panelMeta.layoutMap.list.forEach(colId => {
        console.log("")
        this.panelMeta.layoutMap[colId].list.forEach(rowId => {
          this.panelMeta.layoutMap[colId][rowId].elements.forEach(wid => {
            if(wid.actionConfig?.actions?.length){
              wid.actionConfig.actions.forEach(action => {
                if(action.action == 'application' && action.actionMap.boxId == this.panelMeta.boxId && action.actionMap.action.split('/')[0] == this.panelMeta.boxObjectId){
                  action.actionMap.actionMode = 'update'
                }
              })
            }
          })
        })
      })
      console.log("action modes updated", JSON.parse(JSON.stringify(this.panelMeta)))

      await this.generatePrefillActionConfig(this.rawBoxData);

      let widgets = this.automationService.getWidgetsFromPanel(this.panelMeta);
      // console.log("widgets ", widgets)
      //create page model and push to actionMap if not from bloom
      if(this.panelMeta.fromBloom == false){
        //create page model
        var pageModel = {};
        pageModel[this.panelMeta.id] = {}
        for (let key in widgets){
          var model = {
            widgetId: '',
            widgetName: '',
            widgetType: '',
            value: ''
          }

          model.widgetId = widgets[key].id
          model.widgetName = widgets[key].name
          model.widgetType = widgets[key]?.type

          pageModel[this.panelMeta.id][widgets[key].id] = model

        }
        //push pageModel to actionMap
        let index: number = widgets.length - 1
        widgets[index].actionConfig.actions[0].actionMap.pageModel = pageModel;
        widgets[index].actionConfig.actions[0].actionMap.widgets = widgets;
      }

    } catch(err){
      this.dataLoadError = ''
      console.error("data bind config exec failed for list panel", err)
      this.dataLoadError = `${err.status}  ${err.name}`
    
    } finally{
      this.runSpinner = false;
      this.sps.hide();
      this.manageSpinner.emit({
        panelId: this.panelMeta.id,
        isSpinning: false
      })
    }
  }



  /**
 * generates widget actions for populating form fields with existing values
 */
  async generatePrefillActionConfig(data){

    let actions: any[] = []

    let widgets = this.automationService.getWidgetsFromPanel(this.panelMeta);
    console.log("widgets-->", widgets)
    this.panelMeta.formAttributes.forEach(async(attr: any, i: number) => {

      let id, injectionValue;
      // normal attributes
      if (!attr.isDrillDown) {
        id = attr.__id
        injectionValue = data?.[attr.__id] || '${reset}'
        this.doAction(widgets, id, injectionValue)
      } else {  // nested attributes
        attr.nestedProperties.forEach(async (nestedProp) => {
          console.log("nestedProperty", nestedProp)
          id = attr.__id + "." + nestedProp.path
          injectionValue = this.getDeepObjectValue(data?.[attr.__id], nestedProp.path)
          await this.doAction(widgets, id, injectionValue)
        })
      }
    })
    console.log("injected widgets", this.panelMeta);
    this.metaService.formPanelLoaded = true;
  }

  // find the index of widget associated with this attribute from attribute stamp in the widget id
  async doAction(widgets: any[], id: any, injectionValue: any){
    let j = widgets.findIndex(wid => wid.id.split('-')[1] == id)
    if(j > -1){
      let action: any = {
        action: 'widget',
        actionMap: {
          effectStyles: [],
          mapping: [],
          value: injectionValue,
          widget: this.panelMeta.id + '.' + widgets[j].id,
          widgetActionType: "set_value"
        },
        event: 'click'
      }
      console.log("action", action)
      await this.widgetAction.doAction(action, {type: 'click', widgetMap: widgets[j]})
    }
  }

  private getDeepObjectValue(obj, path){
    let parts = path.split('.')
    let objTemp: any = obj
    let len = parts.length
    for(let j = 0; j < len; j++){
      objTemp = objTemp?.[parts[j]]

      if(objTemp == undefined) break
      if(j !== (len - 1) && (typeof objTemp !== 'object' || Array.isArray(objTemp))){
        console.log("broken path")
        objTemp = undefined
        break
      }
    }
    return objTemp
  }
}
